import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: '',
    packageList: [],
    packageListDetails: [],
    packageRoomTypeDetails: []
}
const packageSlice = createSlice({
    name: 'packageData',
    initialState,
    reducers: {
        savePackageList: (state, action) => {
            if (action.payload == true) {
                state.loader = action.payload;
                state.packageList = []
            } else {
                state.loader = false;
                state.packageList = action.payload
            }
        },

        savePackageListDetails: (state, action) => {
            state.packageListDetails = action.payload;
        },

        savePackageRoomTypeDetails: (state, action) => {
            state.packageRoomTypeDetails = action.payload;
        }
    }
});

export const { savePackageList, savePackageListDetails, savePackageRoomTypeDetails } = packageSlice.actions;

export default packageSlice.reducer;

