import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { useEffect } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";

const ContactUs = () => {

    const staticContentList = useSelector((state) => state.staticContent.staticContent);

    console.log('Contact us content ', staticContentList);
    useEffect(() => {
        actions.getResortListAction();

        let static_content_data = { slug: "contact-us" };
        actions.getStaticContentAction(static_content_data);
    }, []);

    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="PolicyBanner AboutBanner">
                    {/* <h3>Contact Us</h3> */}
                    <h3>{staticContentList?.data?.title}</h3>
                </div>
                <div className="PrivacyPolicyArea">
                    <div className="AboutBox">
                        <div className="AboutLeft">
                            <div dangerouslySetInnerHTML={{ __html: staticContentList?.data?.content }} />
                        </div>
                        <div className="AboutRight">
                            <figure>
                                <img src={require("../../assets/images/about-1.png")} alt="img" />
                            </figure>
                        </div>
                    </div>
                </div>

            </div>
        </FooterHeaderWrapper>
    )
}

export default ContactUs;