import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { useEffect } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";

const AboutUs = () => {

    const staticContentList = useSelector((state) => state.staticContent.staticContent);

    console.log('About us content ', staticContentList);
    useEffect(() => {
        actions.getResortListAction();

        let static_content_data = { slug: "about-us" };
        actions.getStaticContentAction(static_content_data);
    }, []);

    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="PolicyBanner AboutBanner">
                    {/* <h3>About Us</h3> */}
                    <h3>{staticContentList?.data?.title}</h3>
                </div>
                <div className="PrivacyPolicyArea">
                    <div className="AboutBox">
                        <div className="AboutLeft">
                            <div dangerouslySetInnerHTML={{ __html: staticContentList?.data?.content }} />
                            {/* <p>
                                At shadescapesmv.com, 
                                we're more than just a platform – we're your dedicated partners in exploring 
                                the breathtaking beauty of the Maldives. As experts in Maldives destinations, 
                                we understand the hurdles travelers face with traditional booking methods. 
                                That's why we're here to provide unparalleled service, making your journey seamless, 
                                memorable, and filled with wonder. 
                                Let us guide you through the Maldives experience like never before.
                            </p> */}
                        </div>
                        <div className="AboutRight">
                            <figure>
                                {/* <img src={staticContentList?.data?.images ? staticContentList?.data?.images : require("../../assets/images/about-1.png")} alt="img" /> */}
                                <img src={require("../../assets/images/about-1.png")} alt="img" />
                            </figure>
                        </div>
                    </div>

                    {/* <div className="WhyChooseUsArea">
                        <span>Why Choose Us</span>
                        <div className="WhyChooseRight">
                            <h1>Finding your dream stay is our priority</h1>
                            <p>
                                With a collection of destinations that include stunning natural landscapes, vibrant cosmopolitan cities and <br /> enchanting tropical islands, we take you to the world's most stunning places.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="WhyChooseBox">
                                <span><img src={require("../../assets/images/lots-of-choice.png")} alt="img" /></span>
                                <h5>Lots of choices</h5>
                                <p>Thousands of the best destinations are ready to spoil your eyes.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="WhyChooseBox">
                                <span><img src={require("../../assets/images/luxury-stay.png")} alt="img" /></span>
                                <h5>Luxury Stay</h5>
                                <p>No need to be complicated in ordering tickets, order now with a few taps.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="WhyChooseBox">
                                <span><img src={require("../../assets/images/accomodation.png")} alt="img" /></span>
                                <h5>Accomodation</h5>
                                <p>There are many choices of interesting destinations to make stories on your trip.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="WhyChooseBox">
                                <span><img src={require("../../assets/images/best-service.png")} alt="img" /></span>
                                <h5>Best Services</h5>
                                <p>Don't worry about traveling anywhere, our tour guide is ready to guide you anytime.</p>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        </FooterHeaderWrapper>
    )
}

export default AboutUs;