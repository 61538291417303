import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Rings } from 'react-loader-spinner';

const ResortCarousel = () => {

    const responsive = {
        0: {
            items: 1, // Number of items to display on screens smaller than 600px
        },
        600: {
            items: 2, // Number of items to display on screens 600px and up
        },
        1000: {
            items: 3, // Number of items to display on screens 1000px and up
        },
        1200: {
            items: 4, // Number of items to display on screens 1200px and up
        },
    };
    
    let user_details = JSON.parse(localStorage.getItem('user_details'));
    const [loader, setLoader] = useState(false);

    let packageList = useSelector((state) => state.packageReducer.packageList?.data);
    useEffect(() => {
        // debugger
        setLoader(true);
        actions.getPackageListAction()
            .then(() => {
                setLoader(false);
            }).catch((error) => {
                console.error('Error fetching banners:', error);
                setLoader(false);
            })
    }, []);
    console.log('packae list - ', packageList);

    return (
        <section>
            <div className="BlogsArea PackagesFourBoxs">
                <div className="header-title">
                    <h2>
                        <img src={require("../../assets/images/blog-heading.png")} alt="img" />
                        Feature Packages </h2>
                    <p>Plan and book our perfect trip with expert advice, travel tips, destination information and <br />  inspiration from us</p>
                </div>
                {
                    loader ?
                        <div className="loader-container">
                            <Rings
                                visible={true}
                                height="200"
                                width="200"
                                color="#4fa94d"
                                ariaLabel="rings-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                        :
                        packageList && <OwlCarousel className='owl-theme' loop margin={10} nav autoplay items={4} responsive={responsive}> 
                            {packageList && Array.isArray(packageList) && packageList?.map((data, index) => (
                                <Link to={`/package-details/${data?.id}`} key={index}>
                                    <div className="item" >
                                        <div className="PackagesBox">
                                            <div className="Package-img">
                                                {/* <img src="assets/images/package/package-1.png" alt="img" /> */}
                                                <img src={data?.images ? data?.images : require("../../assets/images/package/package-1.png")} alt="img" height={"250px"} />
                                                <div className="Overlay">
                                                    <h6>{data?.package_days}</h6>
                                                </div>
                                            </div>
                                            <h3>{data?.package_name}</h3>
                                            <p><i className="fa-solid fa-location-dot"></i>{data?.resort_address} , {data?.resort_pincode}</p>
                                            <div className="price-box">
                                                {
                                                    user_details ?
                                                        <>
                                                            <h5 className="Price">${parseInt(data?.min_price_per_night) - parseInt(data?.min_discounted_price)}</h5>
                                                            <h5 className="Discount"><del>${data?.min_price_per_night}</del></h5>
                                                        </>
                                                        :
                                                        <>
                                                            <h5 className="Price">${data?.min_price_per_night}</h5>
                                                            {/* <h5 className="Discount"><del>${parseInt(data?.min_price_per_night) - parseInt(data?.min_discounted_price)}</del></h5> */}
                                                        </>
                                                }

                                                {/* <h5 className="Price">$130</h5>
                                        <h5 className="Discount"><del>$150</del></h5> */}
                                                {
                                                    user_details ?
                                                        <h5 className="Percentage">{parseFloat(data?.min_discounted_price * 100 / data?.min_price_per_night).toFixed(2)}%<small>off</small></h5>
                                                        :
                                                        // <h5 className="Percentage"><del>{parseFloat(data?.min_discounted_price * 100 / data?.min_price_per_night).toFixed(2)}</del>%<small>off</small></h5>
                                                        ""
                                                }
                                                {/* <h5 className="Percentage">{parseFloat(data?.min_discounted_price * 100 / data?.min_price_per_night).toFixed(2)}%<small>off</small></h5> */}
                                            </div>
                                            <h6><span className="Green">4.6</span>Excellent<p>(1200 Reviews)</p></h6>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </OwlCarousel>}
                <div className="item d-flex justify-content-center">
                    <Link to="/packages" className="ExploreAllOverlay">Explore All</Link>
                </div>
            </div>
        </section>
    )
}

export default ResortCarousel;