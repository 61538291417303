import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: '',
    resortList: [],
    resortListDetails: [],
    resortListByType: [],
    resortRoomTypeDetails: [],
    resortBookingList: [],
    resortHolidayList: []
}
const resortSlice = createSlice({
    name: 'resortData',
    initialState,
    reducers: {
        saveResortList: (state, action) => {
            console.log("payload", action)
            if (action.payload == true) {
                state.loader = action.payload;
                state.resortList = []
            } else {
                state.loader = false;
                state.resortList = action.payload
            }

        },

        saveResortListDetails: (state, action) => {
            state.resortListDetails = action.payload;
        },
        saveResortBookingList: (state, action) => {
            state.resortBookingList = action.payload;
        },

        saveResortListByType: (state, action) => {
            state.resortListByType = action.payload;
        },

        saveResortRoomTypeDetails: (state, action) => {
            state.resortRoomTypeDetails = action.payload;
        },

        saveResortHolidayList: (state, action) => {
            state.resortHolidayList = action.payload;
        }
    }
});

export const { saveResortList, saveResortListDetails, saveResortBookingList, saveResortListByType, saveResortRoomTypeDetails, saveResortHolidayList } = resortSlice.actions;

export default resortSlice.reducer;

