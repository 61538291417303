
import { useState } from "react";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
// import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";


const ProfileDetails = () => {

    let user_details = JSON.parse(localStorage.getItem('user_details'));
    console.log(user_details?.id);

    const [data, setData] = useState(user_details);
    const [editProfile, setEditProfile] = useState(false)

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value })
    }
    console.log('data --- ', data);

    const submitUpdateUserForm = async (e) => {
        e.preventDefault();
        console.log(new Date(data?.date_of_birth));
        let res = await actions.updateUserDetailsAction(data);
        if (!res) {
            toast.error(res?.data?.message);
        } else {
            setData(res.data.data);
            setEditProfile((prevState) => !prevState)
            localStorage.setItem('user_details', JSON.stringify(res.data.data));
            console.log('Update user details successfully done!');
            toast.success("Update user details successfully done!");
        }
    }
    console.log('user_update --- ', data);

    const handleProfileImage =async (event)=>{
            event.preventDefault();
            console.log(event.target.files[0])
            const file = event.target.files[0]; 
            const formData = new FormData(); 
            formData.append('image', file);
            formData.append('id', data?.id);
            // const imgElement = document.getElementById('profileImage');
            // if (imgElement) {
            //   imgElement.src = event.target.result;
            // }
            let res = await actions.updateProfileImageAction(formData);
            if(!res){
                toast.error(res?.data.message);
            }else{
                setData(res.data.data);
                localStorage.setItem('user_details', JSON.stringify(res.data.data));
                toast.success("Update user profile image successfully done!");
            }
    }


    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="ProfileBanner">
                </div>
                <div className="ProfileArea">
                    <div className="ProfileImage">
                        <figure>
                            {/* <img src="assets/images/profile-img.png" /> */}
                            <img src={data?.image ? data?.image : require("../../assets/images/edit-icon.png")} alt="profile_image" />
                        </figure>
                        <div className="UpdateProfileBox">
                            <input type="file" id="profileImage" className="profileImage" accept="image/*" name="image" onChange={handleProfileImage}/>
                            <img src={require("../../assets/images/edit-icon.png")} alt="profile_image" />
                            <span>Update Profile</span>
                        </div>
                    </div>
                    {!editProfile && <div className="ProfileDetailsBoxMain" >
                        <div className="ProfileDetailsBox">
                            <div className="ProfileDetailsBoxUnder">
                                <h1>{data?.first_name} {data?.last_name}</h1>
                                <ul>
                                    <li>
                                        <p><img src={require("../../assets/images/email.png")} alt="profile-email" />Email</p>
                                        <span>{data.email ? data.email : '-'}</span>
                                    </li>
                                    <li>
                                        <p><img src={require("../../assets/images/mobile.png")} alt="profile-mobile" />Mobile Number</p>
                                        <span>+91 {data.phone_number}</span>
                                    </li>
                                    <li>
                                        <p><img src={require("../../assets/images/birth-date.png")} alt="profile-dob" />Date of Birth</p>
                                        <span>{data.date_of_birth ? moment(data.date_of_birth).format('DD-MM-yyyy') : '-'}</span>
                                    </li>
                                </ul>
                            </div>
                            <Link to="#" className="EditProfileBtn" onClick={() => setEditProfile((prevState) => !prevState)}>Edit your profile</Link>
                        </div>
                    </div>
                    }
                    {editProfile &&
                        <div className="ProfileEditForm d-block">
                            <form onSubmit={submitUpdateUserForm}>
                                <div className="row">
                                    {/* <div className="form-group col-md-3">
                                        <h6>First Name</h6>
                                        <div className="PhoneSearchArea">
                                            <select className="form-control">
                                                <option selected="">Mr.</option>
                                                <option>Miss.</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-9 col-md-12">
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-md-12">
                                                <h6>First Name</h6>
                                                <input type="text" name="first_name" value={data?.first_name} onChange={handleChange} className="form-control" />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-12">
                                                <h6>Last Name</h6>
                                                <input type="text" name="last_name" value={data?.last_name} onChange={handleChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <h6>Email</h6>
                                                <input type="text" name="email" value={data?.email} onChange={handleChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                                <h6>Date of Birth</h6>
                                                <DatePicker
                                                    width='100%'
                                                    className="form-control PaddingDatePicker"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    selected={data?.date_of_birth}
                                                    onChange={(date) => setData({ ...data, date_of_birth: moment(date).format('YYYY-MM-DD') })}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-12">
                                            <button type="submit" className="SaveChanges SaveChangesBtn">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>}

                </div>
            </div>
            
        </FooterHeaderWrapper>
    )
}

export default ProfileDetails;