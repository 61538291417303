import { GETRESORTLIST, GETRESORTLISTDETAILS, GETRESORTLISTBYTYPE, GETRESORTROOMTYPEDETAILS, GETRESORTBOOKINGLIST, GETFILTEREDRESORT, GETHOLIDAYLIST } from "./url";
import axios from "axios";

class ResortServiceClass {
    async getResortListService(data) {
        const headers = {
            "content-type": "application/json"
        }
        let queryString = GETRESORTLIST;
        if (data) {
            queryString += '?';
            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key] !== '' && data[key] !== null && data[key] !== 0 && data[key] !== 'Invalid date') {
                    queryString += `${key}=${data[key]}&`;
                }
            }

            queryString = queryString.slice(0, -1);
        }
        return await axios.get(queryString, {
            headers: headers,
        })
    }

    async getResortListDetailsService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.get(`${GETRESORTLISTDETAILS}/${data}`, {
            headers: headers
        })
    }

    async getResortListByTypeService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.get(`${GETRESORTLISTBYTYPE}/${data}`, {
            headers: headers
        })
    }
    async getResortBookingListService() {
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "appliction/json",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.get(`${GETRESORTBOOKINGLIST}`, {
            headers: headers
        })
    }

    async getResortRoomTypeDetailsService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.post(`${GETRESORTROOMTYPEDETAILS}`, { data }, {
            headers: headers
        })
    }

    async getFilteredResortService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.post(`${GETFILTEREDRESORT}`, data, {
            headers: headers
        })
    }

    async getResortHolidayListService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.get(`${GETHOLIDAYLIST}`, {
            headers: headers
        })

        // try {
        //     const response = await fetch(url, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(data),
        //     });

        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }

        //     const result = await response.json();
        //     setResponseMessage(`Success: ${JSON.stringify(result)}`);
        // } catch (error) {
        //     console.error('Error during POST request:', error);
        //     setResponseMessage(`Error: ${error.message}`);
        // }
    }


}


const ResortService = new ResortServiceClass();

export default ResortService;