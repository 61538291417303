import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staticContent: {}
}
const staticContentSlice = createSlice({
    name: 'staticContentData',
    initialState,
    reducers: {
        staticContentData: (state, action) => {
            state.staticContent = action.payload;
        }
    }
});

export const { staticContentData } = staticContentSlice.actions;

export default staticContentSlice.reducer;
