import { STATICCONTENT } from "./url";
import axios from "axios";

class StaticContentServiceClass {
    async getStaticContent(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.post(`${STATICCONTENT}`,data,{
            headers: headers,
        })
    }
}

const StaticContentService = new StaticContentServiceClass();

export default StaticContentService;