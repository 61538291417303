import { savePackageList, savePackageListDetails, savePackageRoomTypeDetails } from "../slices/packageSlice";
import PackageService from "../../services/PackageService";
import store from "../store";

export const getPackageListAction = (data) => {
    return new Promise((resolve, rejact) => {
        store.dispatch(savePackageList(true));
        PackageService.getPackageListService(data).then((res) => {
            store.dispatch(savePackageList(res.data));
            resolve(res);
        }).catch((error) => {
            store.dispatch(savePackageList(false));
            console.log("error from package list", error);
            rejact(error);
        })
    })
}

export const getPackageListDetailsAction = (data) => {
    return new Promise((resolve, reject) => {
        PackageService.getPackageListDetailsService(data).then((res) => {
            console.log('action - ', res.data);
            store.dispatch(savePackageListDetails(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from package list details", error);
            reject(error);
        })
    })
}

export const getPackageRoomTypeDetailsAction = (data) => {
    return new Promise((resolve, reject) => {
        PackageService.getPackageRoomTypeDetailsService(data).then((res) => {
            console.log('action - ', res.data);
            store.dispatch(savePackageRoomTypeDetails(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from package room type details", error);
            reject(error);
        })
    })
}
export const getFilteredPackageAction = (data) => {
    return new Promise((resolve, reject) => {
        PackageService.getFilteredPackageService(data).then((res) => {
            console.log('action - ', res.data);
            store.dispatch(savePackageList(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from filter package", error);
            reject(error);
        })
    })
}