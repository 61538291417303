import { bannerListData } from "../slices/bannerSlice";
import BannerService from "../../services/BannerService";
import store from "../store";

export const getBannerListAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BannerService.getBannerContent(data).then((res)=>{
            store.dispatch(bannerListData(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from banner list", error);
            rejact(error)
        })
    })
}