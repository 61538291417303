import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bannerList: {}
}
const bannerSlice = createSlice({
    name: 'bannerListData',
    initialState,
    reducers: {
        bannerListData: (state, action) => {
            state.bannerList = action.payload;
        }
    }
});

export const { bannerListData } = bannerSlice.actions;

export default bannerSlice.reducer;
