import { useSelector } from "react-redux";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




const initialState = {

    errors: "",

}


const ResortList = () => {



    let user_details = JSON.parse(localStorage.getItem('user_details'));


    const [iState, updateState] = useState(initialState);
    const { errors } = iState;


    const { filter } = useParams();
    console.log('filter on resort list --- ', filter);
    const Navigate = useNavigate();
    const location = useLocation();
    const searchData = location.state?.searchData;
    console.log('searched data ----- ', searchData);
    const [compareList, setCompareList] = useState([]);
    const { resortList, loader } = useSelector((state) => state.resort);
    // console.log('resort list ---- ', resortList);

    // console.log('searched data by home ------ ',searchData);

    const [filterData, setFilterData] = useState({
        holidayType: searchData?.holidayType,
        search: "",
        budget: "",
        transferType: [],
        offerType: [],
        bedType: [],
        checkInDate: searchData?.checkInDate ? searchData?.checkInDate : new Date(),
        checkOutDate: searchData?.checkOutDate ? searchData?.checkOutDate : ''
    });

    console.log('filter data new start on ----- ', filterData);

    const handleBudgetChange = (event) => {
        // alert(event.target.value);   
        // const newBudget = event.target.value;
        // setFilterData({...filterData, budget: newBudget});
        const isChecked = event.target.checked;
        const newValue = isChecked ? event.target.value : [];
        setFilterData({ ...filterData, budget: newValue });
    };

    useEffect(() => {
        if (searchData) {
            console.log('resort list page resot ====== ', searchData);
            let data = {
                ...searchData,
                checkOutDate: moment(searchData?.checkOutDate)?.format('DD-MM-YYYY'),
                checkInDate: moment(searchData?.checkInDate)?.format('DD-MM-YYYY')
            }
            actions?.getResortListAction(data)
        }
        else {
            console.log("hjkkkkkkk", searchData)
            actions.getResortListAction();
        }
    }, [])

    const handleCompareList = (e, data) => {
        if (e.target.checked) {
            if (compareList?.length < 4) {
                setCompareList([...compareList, data]);
            } else {
                toast.error("Cannot compare more than 4 items")
            }
        }
        else {
            setCompareList(compareList.filter(item => item?.id !== data?.id));
        }
    }

    const handleCompare = () => {
        Navigate('/resort-comparison', { state: { compareList } });
    }

    //filter resort list
    useEffect(() => {
        // if(filterData?.search?.trim()!=="" || filterData?.budget!==""){
        //let formData = new FormData();
        // formData.set("search", filterData?.search);
        // formData.set("budget", filterData?.budget);
        // formData.set('transferType', JSON.stringify(filterData.transferType));
        // formData.set('offerType', JSON.stringify(filterData.offerType));
        let data = {
            "holidayType": filterData?.holidayType,
            "search": filterData?.search,
            "budget": filterData?.budget,
            "transferType": filterData.transferType,
            "offerType": filterData.offerType,
            "bedType": filterData.bedType,
            "checkInDate": filterData.checkInDate,
            "checkOutDate": filterData.checkOutDate
        }
        actions.getFilteredResortAction(data)
        // }
    }, [filterData])

    const handleTransferChange = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;

        setFilterData(prevFilterData => {
            const updatedTransferType = checked
                ? [...prevFilterData.transferType, value] // Add value if checked
                : prevFilterData?.transferType?.filter(item => item !== value); // Remove value if unchecked

            return {
                ...prevFilterData,
                transferType: updatedTransferType
            };
        });
    };

    const handleOfferChange = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;

        setFilterData(prevFilterData => {
            const updatedOfferType = checked
                ? [...prevFilterData.offerType, value] // Add value if checked
                : prevFilterData?.offerType?.filter(item => item !== value); // Remove value if unchecked

            return {
                ...prevFilterData,
                offerType: updatedOfferType
            };
        });
    };

    const handleBedTypeChange = (event) => {
        const value = event.target.value;
        const checked = event.target.checked;

        setFilterData(prevFilterData => {
            const updatedBedType = checked
                ? [...prevFilterData.bedType, value] // Add value if checked
                : prevFilterData?.bedType?.filter(item => item !== value); // Remove value if unchecked

            return {
                ...prevFilterData,
                bedType: updatedBedType
            };
        });
    };

    //search data
    const [formData, setFormData] = useState({
        rooms: searchData?.rooms ? searchData?.rooms : 0,
        adults: searchData?.adults ? searchData?.adults : 0,
        childrens: searchData?.childrens ? searchData?.childrens : 0,
        infant: searchData?.infant ? searchData?.infant : 0
    });

    const handleIncrement = () => {
        setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms + 1 }));
        updateState({ ...iState, errors: '' })
    };
    const handleDecrement = () => {
        if (formData?.rooms > 0) {
            setFormData((prevState) => ({ ...prevState, rooms: prevState?.rooms - 1 }));
            updateState({ ...iState, errors: '' })
        }
    };

    const handleAdultIncrement = () => {
        setFormData((prevState) => ({ ...prevState, adults: prevState?.adults + 1 }));
        updateState({ ...iState, errors: '' })
    }
    const handleAdultDecrement = () => {
        if (formData?.adults > 0) {
            setFormData((prevState) => ({ ...prevState, adults: prevState?.adults - 1 }));
            updateState({ ...iState, errors: '' })
        }
    }

    const handleChildrenIncrement = () => {
        setFormData((prevState) => ({ ...prevState, childrens: prevState?.childrens + 1 }));
        updateState({ ...iState, errors: '' })
    }
    const handleChildrenDecrement = () => {
        if (formData?.childrens > 0) {
            setFormData((prevState) => ({ ...prevState, childrens: prevState?.childrens - 1 }));
            updateState({ ...iState, errors: '' })
        }
    }

    const handleInfantIncrement = () => {
        setFormData((prevState) => ({ ...prevState, infant: prevState?.infant + 1 }));
        updateState({ ...iState, errors: '' })
    }
    const handleInfantDecrement = () => {
        if (formData?.infant > 0) {
            setFormData((prevState) => ({ ...prevState, infant: prevState?.infant - 1 }));
            updateState({ ...iState, errors: '' })
        }
    }


    const handleValidation = () => {

        let error = {};
        let formIsValid = true;
        if (filterData?.checkOutDate == '' && (filterData?.checkInDate !== null || filterData?.checkInDate !== '')) {
            error.checkOutDateError = '*Please select Check-out Date';
            formIsValid = false;

        }
        if (filterData?.checkOutDate !== '' && (filterData?.checkInDate == null || filterData?.checkInDate == '')) {
            error.checkInDateError = '*Please select Check-in Date';
            formIsValid = false;
        }

        if (filterData?.checkOutDate == '' && (filterData?.checkInDate == null || filterData?.checkInDate == '')) {
            error.checkInDateError = '*Please select Check-in Date';
            formIsValid = false;

        }
        if (formData?.rooms == 0) {
            error.roomsError = '*Please select rooms';
            formIsValid = false;
        }
        if (formData?.adults == 0 && formData?.childrens == 0 && formData?.infant == 0) {
            error.TravelersError = '*Please select Travelers';
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }

    const handleSearch = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            let data = {
                rooms: formData?.rooms,
                adults: formData?.adults,
                childrens: formData?.childrens,
                infant: formData?.infant,
                checkOutDate: moment(filterData?.checkOutDate)?.format('DD-MM-YYYY'),
                checkInDate: moment(filterData?.checkInDate)?.format('DD-MM-YYYY')
            }
            actions?.getResortListAction(data)

        }
    }

    const handleRefresh = () => {
        setFilterData({ ...filterData, checkInDate: '', checkOutDate: "" })
        updateState({ ...iState, errors: '' })
        setFormData({ ...formData, adults: 0, rooms: 0, childrens: 0, infant: 0 })
        actions?.getResortListAction()
    }


    console.log("filterData", formData?.adults, formData?.childrens, formData?.infant,
        filterData?.checkOutDate, filterData?.checkInDate,
        formData?.rooms, errors?.checkInDateError)
    return (
        <FooterHeaderWrapper>
            <div className="Filter Grey">
                <div className="HeroSmallBox">
                    <div className="form-group" style={{ width: "80%" }}>
                        <label>Check in</label>
                        <DatePicker
                            name="checkInDate"
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            selected={filterData?.checkInDate}
                            onChange={(date) => {
                                setFilterData((prevState) => ({ ...prevState, checkInDate: date }))
                                updateState({ ...iState, errors: '' })
                            }
                            }
                            placeholderText="DD-MM-YYYY"
                        />
                        <p className="Error">{errors?.checkInDateError}</p>
                    </div>
                </div>
                <div className="HeroSmallBox">
                    <div className="form-group" style={{ width: "80%" }}>
                        <label>Check out</label>
                        <DatePicker
                            name="checkOutDate"
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            minDate={filterData?.checkInDate}
                            selected={filterData?.checkOutDate}
                            onChange={
                                (date) => {
                                    setFilterData((prevState) => ({ ...prevState, checkOutDate: date }))
                                    updateState({ ...iState, errors: '' })
                                }
                            }
                            placeholderText="DD-MM-YYYY"
                        />
                        <p className="Error">{errors?.checkOutDateError}</p>
                    </div>

                </div>
                <div className="HeroSmallBox Custom RoomsTypeHover">
                    <div className="form-group">
                        <h6>Rooms</h6>
                        <p>Number of Rooms ({formData?.rooms})</p>
                    </div>
                    <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                    <div className="RoomsTypesOuter First resortListRoomsType">
                        <div className="RoomsTypesList">
                            <div className="RoomsTypeLeft">
                                <h6>Rooms</h6>
                                <p>Number of Rooms</p>
                            </div>
                            <div className="RoomsTypeRight">
                                <span onClick={handleDecrement}><i className="fa-solid fa-minus"></i></span>
                                <h6>{formData?.rooms}</h6>
                                <input type="hidden" name="rooms" value={formData?.rooms} />
                                <span onClick={handleIncrement}><i className="fa-solid fa-plus"></i></span>


                            </div>

                        </div>

                    </div>
                    <p className="Error" style={{ color: 'red' }}>{errors?.roomsError}</p>
                </div>
                <div className="HeroSmallBox Custom TravelersHover">
                    <div className="form-group">
                        <h6>Travelers</h6>
                        <p>Add Guests ({formData?.adults} + {formData?.childrens} + {formData?.infant})</p>
                    </div>
                    <div className="Overlay"><img src={require("../../assets/images/icon-1.png")} alt="img" /></div>
                    <div className="RoomsTypesOuter Second resortListRoomsType">
                        <div className="RoomsTypesList">
                            <div className="RoomsTypeLeft">
                                <h6>Adults</h6>
                                <p>Ages 13 or above</p>
                            </div>
                            <div className="RoomsTypeRight">
                                <span onClick={handleAdultDecrement}><i className="fa-solid fa-minus"></i></span>
                                <h6>{formData?.adults} <input type="hidden" name="adults" value={formData?.adults} /></h6>
                                <span onClick={handleAdultIncrement}><i className="fa-solid fa-plus"></i></span>
                            </div>
                        </div>
                        <div className="RoomsTypesList">
                            <div className="RoomsTypeLeft">
                                <h6>Children</h6>
                                <p>Ages 2 - 12</p>
                            </div>
                            <div className="RoomsTypeRight">
                                <span onClick={handleChildrenDecrement}><i className="fa-solid fa-minus"></i></span>
                                <h6>{formData?.childrens} <input type="hidden" name="childrens" value={formData?.childrens} /></h6>
                                <span onClick={handleChildrenIncrement}><i className="fa-solid fa-plus"></i></span>
                            </div>
                        </div>
                        <div className="RoomsTypesList">
                            <div className="RoomsTypeLeft">
                                <h6>Infant</h6>
                                <p>Ages 0 - 2</p>
                            </div>
                            <div className="RoomsTypeRight">
                                <span onClick={handleInfantDecrement}><i className="fa-solid fa-minus"></i></span>
                                <h6>{formData?.infant} <input type="hidden" name="infant" value={formData?.infant} /></h6>
                                <span onClick={handleInfantIncrement}><i className="fa-solid fa-plus"></i></span>
                            </div>
                        </div>
                    </div>
                    <p className="Error" style={{ color: 'red' }}>{errors?.TravelersError}</p>
                </div>
                <div className="form-group mb-0">
                    <Link to="" className="SearchBtn" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i></Link>
                </div>
                <div className="form-group mb-0">
                    <Link to="" className="SearchBtn" onClick={handleRefresh}><i className="fa fa-refresh" /></Link>
                </div>
            </div>
            <div className="HotelLisingBg">
                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Resorts</li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-12">

                        <div className="CustomSearchbar">
                            <div className="form-group">
                                <label>Search by property name</label>
                                <div className="SearchBarBox">
                                    <input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, search: e.target.value })} placeholder="eg. Beach westpalm" />
                                    <span><i className="fa-solid fa-magnifying-glass"></i></span>
                                </div>
                            </div>
                        </div>

                        <div className="FilterBy">
                            <p>Filter by</p>
                        </div>

                        <div className="BudgetBox CommonHeading">
                            <h4>Your Budget per day</h4>
                            <div className="BudgetContent">
                                <ul>
                                    <li>
                                        <label className="CheckBox"> $ 0 - $ 200
                                            <input type="checkbox" value="0 - 200" onChange={handleBudgetChange} checked={filterData.budget === "0 - 200"} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>200</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> $ 200 - $ 500
                                            <input type="checkbox" value="200 - 500" onChange={handleBudgetChange} checked={filterData.budget === "200 - 500"} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>100</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> $ 500 - $ 1,000
                                            <input type="checkbox" value="500 - 1000" onChange={handleBudgetChange} checked={filterData.budget === "500 - 1000"} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>15</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> $ 1,000 - $ 2,000
                                            <input type="checkbox" value="1000 - 2000" onChange={handleBudgetChange} checked={filterData.budget === "1000 - 2000"} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>12</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> $ 2,000 - $ 5,000
                                            <input type="checkbox" value="2000 - 5000" onChange={handleBudgetChange} checked={filterData.budget === "2000 - 5000"} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>230</h5> */}
                                    </li>
                                </ul>
                                <div className="ToggleBox">
                                    <div className="form-group">
                                        <h6>Set your own budget</h6>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <form>
                                    <div className="form-group">
                                        <input type="range" className="form-control-range" id="formControlRange" />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="RatingBox CommonHeading">
                            <h4>Rating</h4>
                            <div className="RatingContent">
                                <h6>Show only ratings more than</h6>
                                <ul>
                                    <li>1 <span><i className="fa-solid fa-star"></i></span></li>
                                    <li>2 <span><i className="fa-solid fa-star"></i></span></li>
                                    <li>3 <span><i className="fa-solid fa-star"></i></span></li>
                                    <li>4 <span><i className="fa-solid fa-star"></i></span></li>
                                    <li>5 <span><i className="fa-solid fa-star"></i></span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="BudgetBox CommonHeading">
                            <h4>Bed Type</h4>
                            <div className="BudgetContent">
                                <ul>
                                    <li>
                                        <label className="CheckBox"> King
                                            <input type="checkbox"
                                                value="King"
                                                onChange={handleBedTypeChange}
                                                checked={filterData.bedType.includes("King")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>200</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Queen
                                            <input type="checkbox"
                                                value="Queen"
                                                onChange={handleBedTypeChange}
                                                checked={filterData.bedType.includes("Queen")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>100</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> full bed(s) - 52-59 inches wide
                                            <input type="checkbox"
                                                value="full bed(s) - 52-59 inches wide"
                                                onChange={handleBedTypeChange}
                                                checked={filterData.bedType.includes("full bed(s) - 52-59 inches wide")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>15</h5> */}
                                    </li>


                                </ul>
                                <div className="ToggleBox">
                                    <div className="form-group">
                                        <h6>Set your own budget</h6>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="BudgetBox CommonHeading">
                            <h4>Amenties</h4>
                            <div className="BudgetContent">
                                <ul>
                                    <li>
                                        <label className="CheckBox"> 24hr front desk
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Air-conditioned
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Fitness
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Pool
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                                <Link to="#" className="ReadMore">Read More</Link>
                            </div>
                        </div> */}

                        <div className="BudgetBox CommonHeading">
                            <h4>Transfer Type</h4>
                            <div className="BudgetContent">
                                <ul>
                                    <li>
                                        <label className="CheckBox"> Domestic Transfer
                                            <input type="checkbox"
                                                value="Domestic Transfer"
                                                onChange={handleTransferChange}
                                                checked={filterData.transferType.includes("Domestic Transfer")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>200</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Seaboat
                                            <input type="checkbox"
                                                value="Seaboat"
                                                onChange={handleTransferChange}
                                                checked={filterData.transferType.includes("Seaboat")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>100</h5> */}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="BudgetBox CommonHeading">
                            <h4>Offer Type</h4>
                            <div className="BudgetContent">
                                <ul>
                                    <li>
                                        <label className="CheckBox"> Surfing
                                            <input type="checkbox"
                                                value="Surfing"
                                                onChange={handleOfferChange}
                                                checked={filterData.offerType.includes("Surfing")}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>200</h5> */}
                                    </li>
                                    <li>
                                        <label className="CheckBox"> Latest Holiday 12
                                            <input type="checkbox"
                                                value="Latest Holiday 12"
                                                onChange={handleOfferChange}
                                                checked={filterData.offerType.includes("Latest Holiday 12")} />
                                            <span className="checkmark"></span>
                                        </label>
                                        {/* <h5>100</h5> */}
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-9 col-md-12">
                        <div className="SearchResultArea">
                            {loader ?
                                <p className="load" style={{ textAlign: "center" }}>
                                    <BeatLoader
                                        loading={true}
                                        size={10}
                                    />
                                </p> : <h2><span>{resortList?.count_resort_list ? resortList?.count_resort_list : 0}</span> search results found</h2>

                            }

                            <select className="form-control">
                                <option>Sort by</option>
                                <option>Recommended</option>
                            </select>
                        </div>

                        {
                            resortList?.data && resortList?.data?.map((data, index) => {
                                return (
                                    <>
                                        <div key={index} className="HotelDetailsBox" style={{ cursor: 'pointer' }} onClick={() => Navigate(`/product-details/${data?.id}`, { state: { filterData, formData } })}>
                                            <figure><img src={data?.images} alt="hotel-details" /></figure>
                                            <div className="AddressRecommed">
                                                <div className="HotelAddressBox HotelAddressBox70">
                                                    <h3>{data?.resort_name} </h3>
                                                    <p><i className="fa-solid fa-location-dot"></i>{data?.resort_address} , {data?.resort_pincode}</p>
                                                    {
                                                        data?.resort_rating_data?.resort_rating_count !== 0 ?
                                                            <h6><span className="Green">{data?.resort_rating_data?.average_rating?.toFixed(1)}</span>Excellent<p>({data?.resort_rating_data?.resort_rating_count} Reviews)</p></h6>
                                                            : <h6><span className="Yellow"> No ratings yet </span></h6>
                                                    }

                                                    <ul>
                                                        {
                                                            data?.meal_name ?
                                                                <li><i className="fa-solid fa-check"></i>{data?.meal_name}</li>
                                                                :
                                                                ""

                                                        }


                                                        {data?.allow_early_check == 1 ?
                                                            <li><i className="fa-solid fa-check"></i>Early Check-In</li>
                                                            :
                                                            ""
                                                        }
                                                    </ul>
                                                    <label className="CheckBox AddToCompare" onClick={(e) => e.stopPropagation()}> Add to Compare
                                                        <input type="checkbox" onChange={(e) => handleCompareList(e, data)} checked={compareList.some(item => item.id === data.id)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="HotelRecommend">
                                                    <h5>Recommended for</h5>
                                                    <span>{data?.holiday_type}</span>
                                                    <h4>
                                                        {user_details ?

                                                            <>
                                                                <span>${data?.min_price_per_night}</span>
                                                                ${parseInt(data?.min_price_per_night) - data?.min_discounted_price}
                                                            </>
                                                            :
                                                            <>
                                                                ${data?.min_price_per_night}
                                                                {/* <span>${data?.min_price_per_night - data?.min_discounted_price}</span> */}
                                                            </>
                                                        }

                                                    </h4>
                                                    <p>Per night</p>
                                                    <p>Includes taxes and fees</p>
                                                    {
                                                        user_details ? "" : <Link to="#" className="LoginLink">Login to unlock the best price</Link>
                                                    }
                                                    {/* <Link to="#" className="LoginLink">Login to unlock the best price</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        {/* <div className="LoadMoreResults">
                            <a href="#" className="LoadMoreResultsBtn">Load more results</a>
                        </div> */}
                    </div>
                    {/* <div className="col-md-12">
                        <div className="PinkWarning">
                            <p><img src={require("../../assets/images/danger.png")} alt="warning" />Check the latest COVID-19 restrictions before you travel. <span>Learn more</span></p>
                        </div>
                    </div> */}
                </div>
            </div>


            {compareList && compareList?.length > 0 && <div className="CompareBox" style={{ display: 'block' }}>
                <ul>
                    {
                        compareList && compareList?.map((data, index) => (
                            <li key={index}>
                                <figure><img src={data?.images} alt="pkg" /></figure>
                                <figcaption>
                                    <h5>{data?.resort_name}</h5>
                                    <h4>
                                        {user_details ?
                                            <>
                                                <span>${data?.min_price_per_night} </span>
                                                ${data?.min_price_per_night - data?.min_discounted_price}
                                            </>
                                            :
                                            <>
                                                ${data?.min_price_per_night}
                                                <span>${data?.min_price_per_night - data?.min_discounted_price}</span>
                                            </>
                                        }
                                    </h4>
                                </figcaption>
                            </li>
                        ))
                    }
                </ul>
                <div className="Button">
                    <span className="OutlineBtn" style={{ cursor: 'pointer' }} onClick={() => setCompareList([])}>Remove All</span>
                    <span className="FillBtn" style={{ cursor: 'pointer' }} onClick={handleCompare}>Compare</span>
                </div>
            </div>
            }

        </FooterHeaderWrapper>
    )
}

export default ResortList;