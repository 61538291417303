import Header from "./header";
import Footer from "./footer";
import AdminHeader from "./adminHeader";

import 'react-toastify/dist/ReactToastify.css';

const FooterHeaderWrapper = ({ children ,modalStatus}) => {
    return (
        <>
            {localStorage.getItem('token') ? <AdminHeader  /> : <Header modalStatus={modalStatus}/>}
            {children}
            <Footer />
        </>
    )
}

export default FooterHeaderWrapper;