import { GETPACKAGELIST, GETPACKAGELISTDETAILS, GETPACKAGEROOMTYPEDETAILS, GETFILTEREDPACKAGE } from "./url";
import axios from "axios";

class PackageServiceClass {
    async getPackageListService(data) {
        const headers = {
            "content-type": "application/json"
        }
        let queryString = GETPACKAGELIST;
        if (data) {
            queryString += '?';
            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key] !== '' && data[key] !== null && data[key] !== 0 && data[key] !== 'Invalid date') {
                    queryString += `${key}=${data[key]}&`;
                }
            }

            queryString = queryString.slice(0, -1);
        }

        return await axios.get(queryString, {
            headers: headers,
        })
    }

    async getPackageListDetailsService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.get(`${GETPACKAGELISTDETAILS}/${data}`, {
            headers: headers
        })
    }

    async getPackageRoomTypeDetailsService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.post(`${GETPACKAGEROOMTYPEDETAILS}`, data, {
            headers: headers
        })
    }
    async getFilteredPackageService(data) {
        const headers = {
            "content-type": "appliction/json"
        }

        return await axios.post(`${GETFILTEREDPACKAGE}`, data, {
            headers: headers
        })
    }
}


const PackageService = new PackageServiceClass();

export default PackageService;