import { saveResortList, saveResortListDetails, saveResortListByType, saveResortRoomTypeDetails,saveResortBookingList, saveResortHolidayList} from "../slices/resortSlice";
import ResortService from "../../services/ResortService";
import store from "../store";

export const getResortListAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        store.dispatch(saveResortList(true));
        ResortService.getResortListService(data).then((res)=>{
            store.dispatch(saveResortList(res.data));
            resolve(res);
        }).catch((error)=>{
            store.dispatch(saveResortList(false));
            console.log("error from resort list", error);
            rejact(error);
        })
    })
}
export const getResortBookingListAction =()=>{
    return new Promise((resolve, rejact)=>{
        ResortService.getResortBookingListService().then((res)=>{
            store.dispatch(saveResortBookingList(res.data));
            resolve(res);
        }).catch((error)=>{
            console.log("error from resort booking list", error);
            rejact(error);
        })
    })
}

export const getResortListDetailsAction = (data) => {
    return new Promise((resolve, reject) => {
        ResortService.getResortListDetailsService(data).then((res) => {
            console.log('action - ',res.data);
            store.dispatch(saveResortListDetails(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from resort list details", error);
            reject(error);
        })
    })
}

export const getResortListByTypeAction = (data) => {
    return new Promise((resolve, reject) => {
        ResortService.getResortListByTypeService(data).then((res) => {
            console.log('action - ',res.data);
            store.dispatch(saveResortListByType(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from resort list by type", error);
            reject(error);
        })
    })
}

export const getResortRoomTypeDetailsAction = (data) => {
    return new Promise((resolve, reject) => {
        ResortService.getResortRoomTypeDetailsService(data).then((res) => {
            console.log('resort action - ',res.data);
            store.dispatch(saveResortRoomTypeDetails(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from resort room type details", error);
            reject(error);
        })
    })
}

export const getFilteredResortAction = (data) => {
    return new Promise((resolve, reject) => {
        ResortService.getFilteredResortService(data).then((res) => {
            console.log('action - ',res.data);
            store.dispatch(saveResortList(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from filter resort", error);
            reject(error);
        })
    })
}

export const getResortHolidayAction = (data) => {
    return new Promise((resolve, reject) => {
        ResortService.getResortHolidayListService(data).then((res) => {
            console.log('action - ',res.data);
            store.dispatch(saveResortHolidayList(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from holiday type list", error);
            reject(error);
        })
    })
}