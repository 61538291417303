import { combineReducers } from "@reduxjs/toolkit";
import authReducer from '../slices/authSlice';
import resortReducer from "../slices/resortSlice"; 
import packageReducer from '../slices/packageSlice';
import bookingReducer from '../slices/bookingSlice';
import statcContentReducer from '../slices/staticContentSlice';
import bannerReducer from '../slices/bannerSlice';
import enquiryReducer from '../slices/enquieryManagementSlice';

const rootReducer = combineReducers({
    auth:authReducer,
    resort: resortReducer,
    packageReducer,
    booking:bookingReducer,
    staticContent:statcContentReducer,
    bannerListData:bannerReducer,
    enquiryManagement:enquiryReducer
})

export default rootReducer