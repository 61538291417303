import React from "react";

// Zoho Sales IQ widget code
export default function ZohoSalesIQ(props) {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: "siq3e256af40cadccf109c540c9a44a5dde6d97459a23b7303f67163fd27ad5b26a",
        values: {},
        ready: function () {},
    };
    const d = document;
    let s;
    s = d.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zsiqscript';
    s.defer = true;
    s.src = 'https://salesiq.zohopublic.in/widget';
    let t;
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
    return (
        <React.Fragment>
            <div id='zsiqwidget'></div>
        </React.Fragment>
    );
}

// https://salesiq.zohopublic.in/widget?wc=siq3e256af40cadccf109c540c9a44a5dde6d97459a23b7303f67163fd27ad5b26a
// import React, { useEffect } from 'react';

// const ChatSupport = () => {
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           'https://salesiq.zohopublic.com/visitor/v2/channels/website?widgetcode=siq3e256af40cadccf109c540c9a44a5dde6d97459a23b7303f67163fd27ad5b26a&internal_channel_req=true&browser_language=en&current_domain=http%3A%2F%2Flocalhost&pagetitle=Shadescapes&include_fields=avuid',
//           {
//             method: 'GET',
//           }
//         );
        
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
        
//         const data = await response.json();
//         console.log(data); // Handle the response data as needed
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <React.Fragment>
//             <div id='zsiqwidget'></div>
//         </React.Fragment>
//     </div>
//   );
// };

// export default ChatSupport;
