import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";

const CovidGuidelines = () => {
    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="PolicyBanner">
                    <h3>Covid Guidelines</h3>
                </div>
                <div className="PrivacyPolicyArea">
                    <div className="VerticalTabs">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="TabCommonBox">
                                    <h2>Guidelines for home quarantine</h2>
                                    <h2>Scope</h2>
                                    <p>Detection of a travel related/unrelated suspect case of novel Coronavirus Disease (COVID19) will be followed by rapid isolation of such cases in designated health facilities and line
                                        listing of all contacts of such cases.Home quarantine is applicable to all such contacts of a
                                        suspect or confirmed case of COVID-19.
                                        This intervention will be limited to the initial phase of India reporting only (i) travel related
                                        cases and (ii) focal clusters arising from a travel related/unrelated case where cluster
                                        containment strategy is adopted (iii) Persons coming from COVID-19 affected areas where
                                        local and community transmission is evident.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Definition of contact</h2>
                                    <p>A contact is defined as ahealthyperson that has been in such association with aninfected
                                        person or a contaminated environment as to have exposed and is therefore at a higher risk of
                                        developing disease.

                                        The epidemiological link may have occurred within a 14‐day period before the onset of
                                        illness in the case under consideration.</p>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Instructions for the family members of persons being home quarantined</h5>
                                    <p>Only an assigned family member should be tasked with taking care of the such
                                        person:<br />
                                        • Avoid shaking the soiled linen or direct contact with skin<br />
                                        • Use disposable gloves when cleaning the surfaces or handling soiled linen<br />
                                        • Wash hands after removing gloves<br />
                                        • Visitors should not be allowed<br />
                                        • In case the person being quarantined becomes symptomatic, all his close contacts
                                        will be home quarantined (for 14 days) and followed up for an additional 14days
                                        or till the report of such case turns out negative on lab testing</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-1.png")} alt="img" /></span>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Environmental sanitation </h5>
                                    <p>a. Clean and disinfect frequently touched surfaces in the quarantined person’s room (e.g.
                                        bed frames, tables etc.) daily with 1%Sodium Hypochlorite Solution. <br />
                                        b. Clean and disinfect toilet surfaces daily with regular household bleach solution/phenolic
                                        disinfectants<br />
                                        c. Clean the clothes and other linen used by the person separately using common household
                                        detergent and dry</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-2.png")} alt="img" /></span>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Duration of home quarantine</h5>
                                    <p>The home quarantine period is for 14 days from contact with a confirmed case or earlier if
                                        a suspect case (of whom the index person is a contact) turns out negative on laboratory
                                        testing.</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-3.png")} alt="img" /></span>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Why we process your information</h2>
                                    <p>We generally process your information when we need to do so to fulfill a contractual obligation. European law calls these reasons “legitimate interests.” These “legitimate interests” include:</p>
                                    <ul>
                                        <li>a.preventing risk and fraud</li>
                                        <li>b.answering questions or providing other types of support</li>
                                        <li>c.providing and improving our products and services</li>
                                    </ul>
                                    <p>We only process personal information for these “legitimate interests” after considering the potential risks to your privacy—for example, by providing clear transparency into our privacy practices, offering you control over your personal information where appropriate, limiting the information we keep, limiting what we do with your information, who we send your information to, etc.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How we protect your information</h2>
                                    <p>Our teams work tirelessly to protect your information, and to ensure the security and integrity of our platform. We also have independent auditors assess the security of our data storage and systems that process financial information. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How we use “cookies” and other tracking technologies</h2>
                                    <p>We use cookies and similar tracking technologies on our website and when providing our services. For more information about how we use these technologies please see our Cookie Policy.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How you can reach us</h2>
                                    <p>If you would like to ask about, make a request relating to, or complain about how we process your personal information, you can contact us by email at privacy@bindle.com</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </FooterHeaderWrapper>
    )
}

export default CovidGuidelines;