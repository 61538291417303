import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import Home from "./screen/Home";
import "./App.css"
import AboutUs from './screen/AboutUs';
import Login from "./screen/Login";
import Packages from "./screen/Packages";
import ComparisonPackages from "./screen/Packages/ComparisonPackages";
import ResortDetails from "./components/ResortDetails/resortDetails";
import ProfileDetails from "./screen/Profile";
import BookingDetails from "./components/BookingDetails/bookingDetails";
import BookingList from "./components/BookingDetails/bookingList";
import ResortBookingDetails from "./components/BookingDetails/resortBookingDetails";
import ResortList from "./screen/Resort/resortList";
import ResortComparison from "./screen/Resort/resortComparison";
import ProductDetails from "./screen/Resort/productDetails";
import PrivacyPolicy from "./screen/PrivacyPolicy";
import TermsConditions from "./screen/TermsConditions";
import CovidGuidelines from "./screen/CovidGuidelines";
import ContactUs from "./screen/ContactUs";
import ResortBookingConfirmed from "./components/BookingDetails/resortBookingConfirmed";
import Test from "./screen/Home/test";
import PaymentForm from "./screen/Home/test";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* for development server initial path will /shadescape */}
        <Route path="/" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-conditions" element={<TermsConditions />}></Route>
        <Route path="/covid-guidelines" element={<CovidGuidelines />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/" element={<Login />}></Route>

        <Route path="/packages" element={<Packages />}></Route>
        <Route path="/comparison-packages" element={<ComparisonPackages />}></Route>
        <Route path="/package-details/:package_id" element={<ResortDetails />}></Route>

        <Route path="/booking-details" element={<BookingDetails />}></Route>
        <Route path="/booking-list" element={<BookingList />}></Route>
        <Route path="/resort-booking-details" element={<ResortBookingDetails />}></Route>
        <Route path="/resort-booking-confirmed/:booking_id" element={<ResortBookingConfirmed />}></Route>

        <Route path="/profile-details" element={<ProfileDetails />}></Route>

        <Route path="/resort-list" element={<ResortList />}></Route>
        <Route path="/resort-comparison" element={<ResortComparison />}></Route>
        <Route path="/product-details/:resort_id" element={<ProductDetails />}></Route>
        <Route path="/test" element={<PaymentForm />}></Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
