import { staticContentData } from "../slices/staticContentSlice";
import StaticContentService from "../../services/StaticContentService";
import store from "../store";

export const getStaticContentAction =(data)=>{

    return new Promise((resolve, rejact)=>{
        StaticContentService.getStaticContent(data).then((res)=>{
            store.dispatch(staticContentData(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from static content", error);
            rejact(error)
        })
    })
}