import * as auth from './authActions';
import * as resort from './resortAction';
import * as packageAction from './packageAction';
import * as booking from './bookingAction';
import * as staticContent from './staticContentAction';
import * as bannerListData from './bannerAction';
import * as ratingAction from './ratingActions';
import * as enquiryAction from './enquiryAction';

const actions = {
    ...auth,
    ...resort,
    ...packageAction,
    ...booking,
    ...staticContent,
    ...bannerListData,
    ...ratingAction,
    ...enquiryAction
}

export default actions;