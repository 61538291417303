import { loginUser, loginUserOtp, registeredUser, registeredUserDataOtp, userUpdate,updateProfileImage } from "../slices/authSlice";
import AuthService from "../../services/AuthService";
import store from "../store";

export const loginAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        AuthService.loginService(data).then((res)=>{
            store.dispatch(loginUser(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from login", error);
            rejact(error)
        })
    })
}

export const loginOtpAction = (data) => {
    return new Promise((resolve, rejact) => {
        AuthService.verifyLoginService(data).then((res) => {
            // console.log('loginotp ----- ',res.data.data);
            store.dispatch(loginUserOtp(res.data));
            resolve(res);
        }).catch((error) => {
            console.log("error from login otp");
            rejact(error);
        })
    })
}

export const registerAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        AuthService.registerService(data).then((res)=>{
            store.dispatch(registeredUser(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from registration", error);
            rejact(error)
        })
    })
}

export const registerOtpAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        AuthService.verifyRegisterService(data).then((res)=>{
            store.dispatch(registeredUserDataOtp(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from registration otp", error);
            rejact(error)
        })
    })
}

export const updateUserDetailsAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        AuthService.updateUserService(data).then((res)=>{
            store.dispatch(userUpdate(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from update user details", error);
            rejact(error)
        })
    })
}

export const updateProfileImageAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        AuthService.updateProfileImageService(data).then((res)=>{
            store.dispatch(updateProfileImage(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from updateProfileImage", error);
            rejact(error)
        })
    })
}