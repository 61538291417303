import { SENDENQUIRY } from "./url";
import axios from "axios";

class EnquiryServiceClass {
    async postEnquiryService(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "appliction/json",
            "Authorization": `Bearer ${access_token}`
        }
        return await axios.post(`${SENDENQUIRY}`,data,{
            headers: headers
        })
    }
}


const EnquiryData = new EnquiryServiceClass();

export default EnquiryData;