import { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Rings } from 'react-loader-spinner';

const BannerCarousel = () => {

    let bannerList = useSelector((state) => state.bannerListData.bannerList?.data);

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        // debugger
        setLoader(true);

        actions.getBannerListAction()
            .then(() => {
                setLoader(false);
            })
            .catch((error) => {
                console.error('Error fetching banners:', error);
                setLoader(false);
            });
    }, []);
    console.log('banner list on home page --- ', bannerList);

    const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i.test(url);
    };

    const isVideo = (url) => {
        return /\.(mp4|mov|wmv|avi|mkv|flv|webm)$/i.test(url);
    };

    return (
        <section>

            {loader ?
                <div className="loader-container">
                    <Rings
                        visible={true}
                        height="200"
                        width="200"
                        color="#4fa94d"
                        ariaLabel="rings-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                :
                bannerList && <OwlCarousel className='owl-theme' loop margin={10} nav autoplay items={1}>
                    {bannerList && Array.isArray(bannerList) && bannerList?.map((data, index) => (
                        <div id="carouselExampleCaptions" className="carousel slide ViewAllPhotos" data-ride="carousel">

                            <div className="carousel-inner carousel-inner-new">
                                <div className="carousel-item active">
                                    {
                                        isImage(data?.images) ?
                                            <img src={data?.images ? data?.images : require("../../assets/images/banner-1.png")} className="d-block w-100" alt="..." />
                                            : isVideo(data?.images) ?
                                                <video key={index} controls >
                                                    <source src={data?.images} type={`video/${data?.images.split('.').pop()}`} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                : <p key={index}>Unsupported media type: {data?.images}</p>
                                    }

                                    <div className="carousel-caption d-none d-md-block">
                                        <span><img src={require("../../assets/images/thumb.png")} alt="img" />Best Selling Resort</span>
                                        {/* <h1>Velaa Private Island <br /> Maldives: The Dream Resort</h1> */}
                                        <h1>{data?.banner_tittle} <br /> Maldives: The Dream Resort</h1>
                                        <p>Trust your vacation to our travel experts and experience the unforgettable sensation <br /> of exploring
                                            the world.
                                        </p>
                                        {/* <span><img src={require("../../assets/images/camera-icon.png")} alt="img" />View All Photos</span> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}

                </OwlCarousel>
            }


        </section>
    )
}

export default BannerCarousel;