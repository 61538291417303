import {config} from '../constants';

export const getApiUrl =(endpoint)=>{
    return config.API_BASE_URL +endpoint;
}

export const LOGINAPI = getApiUrl('/api/login');
export const VERIFYLOGIN = getApiUrl('/api/verify-login-otp');
export const REGISTERAPI = getApiUrl('/api/register');
export const VERIFYREGISTRATION = getApiUrl('/api/verify-registration-otp');
export const UPDATEUSERDETAILS = getApiUrl('/api/update-profile');
export const UPDATEPROFILEIMAGE = getApiUrl('/api/update-profile-image');
//resort
export const GETRESORTLIST = getApiUrl('/api/get-resort-list');
export const GETRESORTBOOKINGLIST = getApiUrl('/api/get-resort-booking-list');
export const GETRESORTLISTDETAILS = getApiUrl('/api/get-resort-list-details');
export const GETRESORTLISTBYTYPE = getApiUrl('/api/resort-list-by-resort-type');
export const GETRESORTROOMTYPEDETAILS = getApiUrl('/api/get-resort-room-type-details');
export const GETFILTEREDRESORT = getApiUrl('/api/filter-resorts');
export const GETHOLIDAYLIST = getApiUrl('/api/get-holiday-list');

//pakage  
export const GETPACKAGELIST = getApiUrl('/api/get-package-list');
export const GETPACKAGELISTDETAILS = getApiUrl('/api/get-package-list-details');
export const GETPACKAGEROOMTYPEDETAILS = getApiUrl('/api/get-package-roomtype-details');
export const GETFILTEREDPACKAGE = getApiUrl('/api/filter-packages');
//Booking
export const BOOKPACKAGE = getApiUrl('/api/create-booking');
export const CANCELPACKAGEBOOKING = getApiUrl('/api/cancel-package-booking');
export const CANCELBOOKING = getApiUrl('/api/cancel-resort-booking');
export const PACKAGEBOOKINGLIST = getApiUrl('/api/get-booking-list');
export const BOOKRESORT = getApiUrl('/api/create-resort-booking');
export const RESORTBOOKINGDETAILS = getApiUrl('/api/get-resort-booking-details');

//Static Content
export const STATICCONTENT = getApiUrl('/api/get-static-content');

//Banner 
export const BANNERLIST = getApiUrl('/api/web-banner-list');

//rating
export const RESORTRATINGAPI = getApiUrl('/api/create-resort-rating');

//Enquiry
export const SENDENQUIRY = getApiUrl('/api/add-enquiry');
