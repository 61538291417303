import { useEffect, useState } from "react";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import actions from "../../redux/actions";
import moment from "moment";


const ComparisonPackages = () => {
    let user_details = JSON.parse(localStorage.getItem('user_details'));

    const location = useLocation()
    const navigate = useNavigate();
    const compareListData = location.state?.compareList;
    const [compareList, setCompareList] = useState([])
    console.log('resort compare list--- ', compareList)

    useEffect(() => {
        setCompareList(compareListData)
    }, [compareListData]);

    const removePackage = (id) => {
        const updatedCompareList = compareList.filter(item => item.id !== id);
        setCompareList(updatedCompareList);
        if (updatedCompareList.length === 0) {
            navigate('/packages');
        }
    }

    //handle enquery
    const handleEnquery = async (package_id, package_name, package_days, room_type, starting_date, ending_date) => {
        let data = {
            package_id: package_id,
            package_name: package_name,
            room_type: room_type,
            room_adult: 2,
            night: package_days,
            check_in: moment(starting_date).format("yyyy-MM-DD"),
            check_out: moment(ending_date).format("yyyy-MM-DD"),
            status: '1'
        }

        let res = await actions.postEnquiryAction(data);
        if (res) {
            toast.success("Enquiry successfully submitted");
        } else {
            toast.error("Something went wrong");
        }
    }

    return (
        <FooterHeaderWrapper>
            <div className="HotelLisingBg">
                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/packages">Packages</Link></li>
                        <li className="active">Package Comparison</li>
                    </ul>
                </div>
                <div className="ComparisonTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Package <br /> Comparison</th>
                                {compareList && compareList?.map((data, index) => (
                                    <th key={index}>
                                        <div className="ComparisonHotel">
                                            <figure><img src={data?.images ? data?.images : require("../../assets/images/resort-1.png")} alt="comparison" /></figure>
                                            <div className="ComparisonContent">
                                                <h1>{data?.package_name}</h1>
                                                <p><i className="fa-solid fa-location-dot"></i>{data?.resort_address}, {data?.pincode ? data?.pincode : 12345}</p>
                                            </div>
                                            <Link to={`/package-details/${data?.id}`} className="BookBtn" >Book</Link>
                                            <span className="SendEnquiry" onClick={() => handleEnquery(data?.id, data?.package_name, data?.package_days, data?.room_type1, data?.min_rate_starts_from, data?.min_rate_ends_on)} style={{ cursor: 'pointer' }}>Send Enquiry</span>
                                            <span className="Overlay" onClick={() => { removePackage(data?.id) }} style={{ cursor: 'pointer' }}><i className="fa-solid fa-xmark"></i></span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Pricing</td>
                                {compareList && compareList?.map((data, index) => (
                                    <td key={index}>
                                        <div className="Price">
                                            <h1>
                                                {/* <span>$150</span> */}
                                                {/* ${data?.min_price_per_night} */}
                                                {
                                                    user_details ? <>
                                                        <span>${data?.min_price_per_night}</span>
                                                        ${data?.min_price_per_night - data?.min_discounted_price}
                                                    </>
                                                        : <>
                                                            ${data?.min_price_per_night}
                                                        </>
                                                }
                                            </h1>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Rating</td>
                                {compareList && compareList?.map((data, index) => (
                                    <td key={index}><div className="Review"><h6><span className="LightGreen">4.6</span>Good<p>(1200 Reviews)</p></h6></div></td>
                                ))}
                            </tr>
                            <tr>
                                <td>Transfer Type</td>
                                {compareList && compareList?.map((data, index) => (
                                    <td key={index}>{data?.transfer_type}</td>
                                ))}
                            </tr>
                            <tr>
                                <td>Amenities</td>
                                {compareList && compareList.map((data, index) => (
                                    <td key={index}>
                                        <div className="Facilities">
                                            <ul >
                                                {data?.amenities && data?.amenities.map((val, amenityIndex) => (
                                                    <li key={amenityIndex}>
                                                        <i className="fa-solid fa-check"></i>{val}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </FooterHeaderWrapper>
    )
}

export default ComparisonPackages;