import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loginUser: {},
    loginUserOtp: {},
    registeredUserData: {},
    registeredUserDataOtp: {},
    updateUser: {},
    pofileImage:{}
}
const authSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.userData = action.payload;
            // localStorage.setItem("token", action.payload.access_token)
            // localStorage.setItem("user_details", action.payload);
        },

        loginUserOtp: (state, action) =>{
            state.userData = action.payload;
            console.log(action?.payload,"hjhgjhghjgg")
            localStorage.setItem("token", action.payload.access_token);
            localStorage.setItem("user_details", JSON.stringify(action.payload.data));
        },

        registeredUser: (state, action) => {
            state.registeredUserData = action.payload;
        },

        registeredUserDataOtp: (state, action) => {
            state.registeredUserDataOtp = action.payload;
        },

        userUpdate: (state, action) => {
            state.updateUser = action.payload;
        },
        updateProfileImage: (state, action) => {
            state.pofileImage = action.payload;
        }
    }
});

export const { registeredUser, registeredUserDataOtp, loginUser, loginUserOtp, userUpdate ,updateProfileImage} = authSlice.actions;

export default authSlice.reducer;
