import EnquiryService from "../../services/EnquiryService";

export const postEnquiryAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        EnquiryService.postEnquiryService(data).then((res)=>{
            resolve(res);
        }).catch((error)=>{
            console.log("error from post enquiry", error);
            rejact(error);
        })
    })
}