import RatingService from "../../services/RatingService";

export const postResortRatingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        RatingService.postResortRatingService(data).then((res)=>{
            resolve(res);
        }).catch((error)=>{
            console.log("error from resort rating", error);
            rejact(error);
        })
    })
}