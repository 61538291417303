import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { useEffect } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";

const TermsConditions = () => {

    const staticContentList = useSelector((state) => state.staticContent.staticContent);

    console.log('Terms and conditions content ', staticContentList);
    useEffect(() => {
        actions.getResortListAction();

        let static_content_data = { slug: "terms-condition" };
        actions.getStaticContentAction(static_content_data);
    }, []);

    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="PolicyBanner">
                    {/* <h3>Terms & Conditions</h3> */}
                    <h3>{staticContentList?.data?.title}</h3>
                </div>
                <div className="PrivacyPolicyArea">
                    <div className="VerticalTabs">
                        <div className="row">
                            <div className="col-md-12">

                                {/* <div className="TabCommonBox">
                                    <h2>Terms of Use </h2>
                                    <p className="mb-4">These terms of use outline the rules and regulations for the use of our website.
                                        By accessing this website, we assume you accept these terms of use in full.
                                        Do not continue to use shadescapesmv.com, if you do not accept all of the terms of use stated on this page.
                                    </p>
                                </div> */}

                                <div className="TabCommonBox TermsConditionsContent">
                                    <div dangerouslySetInnerHTML={{ __html: staticContentList?.data?.content }} />
                                </div>

                                {/* <div className="TabCommonBox">
                                    <h2>Terms of Use </h2>
                                    <p className="mb-4">These terms of use outline the rules and regulations for the use of our website.
                                        By accessing this website, we assume you accept these terms of use in full.
                                        Do not continue to use shadescapesmv.com, if you do not accept all of the terms of use stated on this page.
                                    </p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>License to Use</h2>
                                    <p>Unless otherwise stated, shadescapesmv.com, and/or its licensors own the
                                        intellectual property rights for all material on shadescapesmv.com.
                                        All intellectual property rights are reserved. You may view and/or print
                                        pages from shadescapesmv.com for your own personal use subject to restrictions
                                        set in these terms of use.</p>
                                </div>

                                <div className="TabCommonBox YouMustNot">
                                    <h2>You must not:</h2>
                                    <ol>
                                        <li>Republish material from shadescapesmv.com.</li>
                                        <li>Sell, rent, or sub-license material from shadescapesmv.com.</li>
                                        <li>Reproduce, duplicate, or copy material from shadescapesmv.com.</li>
                                        <li>Redistribute content from shadescapesmv.co</li>
                                    </ol>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>User Comments</h2>
                                    <p>Certain parts of this website offer the opportunity for users to post and exchange
                                        opinions, information, material, and data ('Comments') in areas of the website.
                                        shadescapesmv.com does not screen, edit, publish, or review Comments prior to their
                                        appearance on the website and Comments do not reflect the views or opinions of
                                        shadescapesmv.com, its agents, or affiliates. Comments reflect the view and opinion
                                        of the person who posts such view or opinion. To the extent permitted by applicable
                                        laws, shadescapesmv.com shall not be responsible or liable for the Comments or for any
                                        loss, cost, liability, damages, or expenses caused and or suffered as a result of any
                                        use of and/or posting of and/or appearance of the Comments on this website. </p>
                                    <p>shadescapesmv.com reserves the right to monitor all Comments and to remove any
                                        Comments which it considers in its absolute discretion to be inappropriate,
                                        offensive, or otherwise in breach of these Terms of Use.</p>
                                </div>

                                <div className="TabCommonBox YouMustNot">
                                    <h2>Disclaimer</h2>
                                    <p>To the maximum extent permitted by applicable law, we exclude all representations,
                                        warranties, and conditions relating to our website and the use of this website
                                        (including, without limitation, any warranties implied by law in respect of
                                        satisfactory quality, fitness for purpose, and/or the use of reasonable care
                                        and skill).
                                        Nothing in this disclaimer will: </p>
                                    <ol>
                                        <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                                        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                                    </ol>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Reservation of Rights</h2>
                                    <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon such request. We also reserve the right to amend these terms of use and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms of use.
                                    </p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Removal of links from our website</h2>
                                    <p>If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Changes to the Terms of Use</h2>
                                    <p>shadescapesmv.com may revise these terms of use for its website at any time without notice. By using this website, you are agreeing to be bound by the then-current version of these terms of use.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Governing Law</h2>
                                    <p>These terms and conditions are governed by and construed in accordance with the laws of The Maldives and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
                                </div> */}

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </FooterHeaderWrapper>
    )
}

export default TermsConditions;