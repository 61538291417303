import { BOOKPACKAGE, PACKAGEBOOKINGLIST, BOOKRESORT, CANCELBOOKING, CANCELPACKAGEBOOKING, RESORTBOOKINGDETAILS} from "./url";
import axios from "axios";

class BookingServiceClass {

    async createBooking(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            // "content-type": "multipart/form-data",
            "content-type": "application/json",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.post(`${BOOKPACKAGE}`,data,{
            headers: headers,
        })
    }

    async cancelBooking(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.post(`${CANCELBOOKING}`,data,{
            headers: headers,
        })
    }
    
    async cancelPackageBooking(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.post(`${CANCELPACKAGEBOOKING}`,data,{
            headers: headers,
        })
    }
    
    async getPackageBooking(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.get(`${PACKAGEBOOKINGLIST}`,{
            headers: headers,
        })
    } 

    async createResortBooking(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "application/json",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.post(`${BOOKRESORT}`,data,{
            headers: headers,
        })
    }

    async getResortBookingDetails(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "application/json",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.get(`${RESORTBOOKINGDETAILS}/${data}`,{
            headers: headers,
        })
    }

}

const BookingService = new BookingServiceClass()

export default BookingService;