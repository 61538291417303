import React from 'react';

const loadRazorpayScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

// https://transaction.merchants.bankofmaldives.com.mv/66791b8c84b398d78d8f3124/paynow

const makePayment = async () => {
    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
        alert('Failed to load Razorpay SDK');
        return;
    }
    // Trigger the payment
    const options = {
        key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImI3NTVjMmYxLWFlMzItNDcwZi1iZjI0LTcwNmYyM2QyOTQ0MCIsImNvbXBhbnlJZCI6bnVsbCwiaWF0IjoxNzE4MDg2MjEzLCJleHAiOjQ4NzM3NTk4MTN9.CW1_I6NsNgyRbkv2E54l9UHQpaospsym3phO-WHAtFA',
        amount: '5000',
        currency: 'MVR',
        name: 'ShadeScape',
        description: 'Test Transaction',
        image: 'https://previews.123rf.com/images/keath/keath1609/keath160900267/63404714-payment-icon-money-and-payment-red-button-badge-illustration.jpg',
        handler: function (response) {
            alert(response.razorpay_payment_id);
        },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};

const Test = () => (
    <div>
        <button onClick={makePayment}>Pay Now</button>
    </div>
);
export default Test;