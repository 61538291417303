import { createBooking, getPackageBooking, createResortBooking, getResortBookingDetail } from "../slices/bookingSlice";
import BookingService from "../../services/BookingService";
import store from "../store";

export const createBookingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.createBooking(data).then((res)=>{
            store.dispatch(createBooking(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from create booking", error);
            rejact(error)
        })
    })
}

export const cancelBookingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.cancelBooking(data).then((res)=>{
            //store.dispatch(createBooking(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from cancel booking", error);
            rejact(error)
        })
    })
}

export const cancelPackageBookingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.cancelPackageBooking(data).then((res)=>{
            //store.dispatch(createBooking(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from cancel package booking", error);
            rejact(error)
        })
    })
}

export const getPackageBookingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.getPackageBooking(data).then((res)=>{
            store.dispatch(getPackageBooking(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from getting list of package booking", error);
            rejact(error)
        })
    })
}

export const createResortBookingAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.createResortBooking(data).then((res)=>{
            store.dispatch(createResortBooking(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from create resort booking", error);
            rejact(error)
        })
    })
}

export const getResortBookingDetailsAction =(data)=>{
    return new Promise((resolve, rejact)=>{
        BookingService.getResortBookingDetails(data).then((res)=>{
            store.dispatch(getResortBookingDetail(res.data))
            resolve(res)
        }).catch((error)=>{
            console.log("error from create resort booking details", error);
            rejact(error)
        })
    })
}
