import { Link } from "react-router-dom";

const Footer = ()=>{
    return (
        <>

            <footer>
                <h2>Explore the <span>Maldives</span> with <img src={require("../../assets/images/logo.png")} alt="footer-heading-logo" /> for
                unforgettable stays.</h2>
                <hr />
                <div className="Footer-Center">
                <div className="FooterInfo">
                    <Link to="/"><img src={require("../../assets/images/logo.png")} alt="footer-logo" /></Link>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br /> sed do eiusmod tempor incididunt ut labore et
                    dolore <br /> magna aliqua.</p>
                </div>
                <div className="FooterLinks">
                    <h5>Company</h5>
                    <ul>
                    <li><Link to="/about-us">About</Link></li>
                    <li><Link to="/resort-list">Resorts</Link></li>
                    <li><Link to="/contact-us">Contact us</Link></li>
                    </ul>
                </div>
                <div className="FooterLinks">
                    <h5>Terms and Policies</h5>
                    <ul>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/terms-conditions">Terms and conditions</Link></li>
                    </ul>
                </div>
                </div>
                <div className="footer-bottom">
                <div className="SocialIcons">
                    <ul>
                    <li><Link to="#"><i className="fa-brands fa-twitter"></i></Link></li>
                    <li><Link to="#"><i className="fa-brands fa-facebook"></i></Link></li>
                    <li><Link to="#"><i className="fa-brands fa-instagram"></i></Link></li>
                    </ul>
                </div>
                <p>&#169; Copyright | Shadescape 2024</p>
                </div>
            </footer>
        
        </>
    );
}

export default Footer;