import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    createNewBooking: {},
    getPackageBookingList: {},
    crateResortBooking: {},
    getResortBookingDetails: {}
}
const bookingSlice = createSlice({
    name: 'bookingData',
    initialState,
    reducers: {
        createBooking: (state, action) => {
            state.createNewBooking = action.payload;
        },

        getPackageBooking: (state, action) => {
            state.getPackageBookingList = action.payload;
        },

        createResortBooking: (state, action) => {
            state.crateResortBooking = action.payload;
        },

        getResortBookingDetail: (state, action) => {
            state.getResortBookingDetails = action.payload;
        }
    }
});

export const { createBooking, getPackageBooking, createResortBooking, getResortBookingDetail} = bookingSlice.actions;

export default bookingSlice.reducer;
