import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    enquiryManagement: {}
}
const enquieryManagementSlice = createSlice({
    name: 'enquiryManagementData',
    initialState,
    reducers: {
        enquiryManagementData: (state, action) => {
            state.enquiryManagement = action.payload;
        }
    }
});

export const { enquiryManagementData } = enquieryManagementSlice.actions;

export default enquieryManagementSlice.reducer;
