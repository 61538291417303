import { RESORTRATINGAPI } from "./url";
import axios from "axios";

class RatingServiceClass {
    async postResortRatingService(data){
        const access_token = localStorage.getItem('token');
        const headers = {
            "content-type": "appliction/json",
            "Authorization": `Bearer ${access_token}`
        }
        return await axios.post(`${RESORTRATINGAPI}`,data,{
            headers: headers
        })
    }
}


const ResortRating = new RatingServiceClass();

export default ResortRating;