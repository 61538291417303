import { useEffect, useState } from "react";
import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import actions from "../../redux/actions";
import moment from "moment";

const ResortComparison = () => {
    let user_details = JSON.parse(localStorage.getItem('user_details'));

    const location = useLocation();
    const navigate = useNavigate();
    const compareListData = location.state?.compareList;
    const [compareList, setCompareList] = useState([]);
    console.log('resort compare list--- ', compareList)

    useEffect(() => {
        setCompareList(compareListData)
    }, [compareListData]);

    const removeResort = (id) => {
        const updatedCompareList = compareList.filter(item => item.id !== id);
        setCompareList(updatedCompareList);
        if (updatedCompareList.length === 0) {
            navigate('/resort-list');
        }
    }

    //send enquiry
    const handleEnquiry = async (resort_id, resort_name, resort_room_type, starting_date, ending_date) => {
        let data = {
            resort_id: resort_id,
            resort_name: resort_name,
            room_type: resort_room_type,
            room_adult: 2,
            night: 2,
            check_in: moment(starting_date).format("yyyy-MM-DD"),
            check_out: moment(ending_date).format("yyyy-MM-DD"),
            status: '1'
        }

        let res = await actions.postEnquiryAction(data);
        if (res) {
            toast.success("Enquiry successfully submitted");
        } else {
            toast.error("Something went wrong");
        }

    }

    return (
        <FooterHeaderWrapper>
            <div className="HotelLisingBg">
                <div className="HotelBreadcumb">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/resort-list">Resorts</Link></li>
                        <li className="active">Resort Comparison</li>
                    </ul>
                </div>
                <div className="ComparisonTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Resort <br /> Comparison</th>
                                {compareList && compareList?.map((data, index) => (
                                    <th key={index}>
                                        <div className="ComparisonHotel">
                                            <figure><img src={data?.images ? data?.images : require("../../assets/images/resort-1.png")} alt="comparison" /></figure>
                                            <div className="ComparisonContent">
                                                <h1>{data?.resort_name}</h1>
                                                <p><i className="fa-solid fa-location-dot"></i>{data?.resort_address}, {data?.pincode ? data?.pincode : 12345}</p>
                                            </div>
                                            <Link to={`/product-details/${data?.id}`} className="BookBtn" >Book</Link>
                                            <span className="SendEnquiry" onClick={() => handleEnquiry(data?.id, data?.resort_name, data?.room_type, data?.min_rate_starts_from, data?.min_rate_ends_on)} style={{ cursor: "pointer" }}>Send Enquiry</span>
                                            <span className="Overlay" onClick={() => { removeResort(data?.id) }} style={{ cursor: 'pointer' }}><i className="fa-solid fa-xmark"></i></span>
                                        </div>
                                    </th>
                                ))}


                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Pricing</td>
                                {compareList && compareList?.map((data, index) => (
                                    <td key={index}>
                                        <div className="Price">
                                            <h1>
                                                {/* <span>$150</span> */}
                                                {
                                                    user_details ? <>
                                                        <span>${data?.min_price_per_night}</span>
                                                        ${data?.min_price_per_night - data?.min_discounted_price}
                                                    </>
                                                        : <>
                                                            ${data?.min_price_per_night}
                                                        </>
                                                }
                                                {/* ${data?.min_price_per_night} */}
                                            </h1>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Rating</td>
                                {compareList && compareList?.map((data, index) => (
                                    data?.resort_rating_data?.resort_rating_count !== 0 ?
                                    <td key={index}><div className="Review"><h6><span className="Green">{data?.resort_rating_data?.average_rating?.toFixed(1)}</span>Excellent<p>({data?.resort_rating_data?.resort_rating_count} Reviews)</p></h6></div></td>
                                    : <td key={index}><div className="Review"><h6><span className="Yellow"> No ratings yet </span></h6></div></td>
                                ))}
                            </tr>
                            <tr>
                                <td>Transfer Type</td>
                                {compareList && compareList?.map((data, index) => (

                                    // <td key={index}>
                                    //     <div className="Facilities">
                                    //         <ul >
                                    //             {data?.tranfer_type && data?.tranfer_type?.map((val, transferIndex) => (
                                    //                 <li key={transferIndex}><i className="fa-solid fa-check"></i>{val}</li>
                                    //             ))}
                                    //         </ul>
                                    //     </div>
                                    // </td>
                                    <td key={index}>
                                        {data?.tranfer_type && data?.tranfer_type?.map((val, index) => (
                                            <p key={index}>{val}</p>
                                        ))}
                                    </td>

                                ))}
                            </tr>
                            <tr>
                                <td>Amenities</td>
                                {compareList && compareList.map((data, index) => (
                                    <td key={index}>
                                        <div className="Facilities">
                                            <ul >
                                                {data?.resort_amenities && data?.resort_amenities.map((val, amenityIndex) => (
                                                    <li key={amenityIndex}>
                                                        <i className="fa-solid fa-check"></i>{val}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </td>
                                ))}

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </FooterHeaderWrapper>
    )
}

export default ResortComparison;