import FooterHeaderWrapper from "../../components/layout/footerHeaderWrapper";
import { useEffect } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {

    const staticContentList = useSelector((state) => state.staticContent.staticContent);

    console.log('About us privacy policy ', staticContentList);
    useEffect(() => {
        actions.getResortListAction();

        let static_content_data = { slug: "privacy-policy" };
        actions.getStaticContentAction(static_content_data);
    }, []);

    return (
        <FooterHeaderWrapper>
            <div id="Main">
                <div className="PolicyBanner">
                    {/* <h3>Privacy Policy</h3> */}
                    <h3>{staticContentList?.data?.title}</h3>
                </div>
                <div className="PrivacyPolicyArea">
                    <div className="VerticalTabs">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="TabCommonBox">
                                    <div dangerouslySetInnerHTML={{ __html: staticContentList?.data?.content }} />
                                </div>

                                {/* <div className="TabCommonBox">
                                    <h2>Introduction</h2>
                                    <p className="mb-3">This Policy details our commitment to protecting the privacy of individuals <br /> who:</p>
                                    <ul>
                                        <li>a.It is now nearly impossible to find out which dribbble designer started the “modern” trend around 2016.</li>
                                        <li>b.This modern trend looks nice and all, but we fell into the same trap again.</li>
                                        <li>c.There is however a very sci-fi option still on the table as well.</li>
                                    </ul>
                                    <p>This Privacy Policy will help you better understand how we collect, use, and share your personal information. If we change our privacy practices, we may update this privacy policy. If any changes are significant, we will let you know.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Our Values</h2>
                                    <p>Trust is the foundation of the Bindle website and includes trusting us to do the right thing with your information. Three main values guide us as we develop our products and services. These values should help you better understand how we think about your information and privacy.</p>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Your information belongs to you</h5>
                                    <p>We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it.</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-1.png")} alt="img" /></span>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Your information belongs to you</h5>
                                    <p>We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it.</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-2.png")} alt="img" /></span>
                                </div>
                                <div className="OurValuesBox">
                                    <h5>Your information belongs to you</h5>
                                    <p>We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it.</p>
                                    <span className="Overlay"><img src={require("../../assets/images/value-3.png")} alt="img" /></span>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>Why we process your information</h2>
                                    <p>We generally process your information when we need to do so to fulfill a contractual obligation. European law calls these reasons “legitimate interests.” These “legitimate interests” include:</p>
                                    <ul>
                                        <li>a.preventing risk and fraud</li>
                                        <li>b.answering questions or providing other types of support</li>
                                        <li>c.providing and improving our products and services</li>
                                    </ul>
                                    <p>We only process personal information for these “legitimate interests” after considering the potential risks to your privacy—for example, by providing clear transparency into our privacy practices, offering you control over your personal information where appropriate, limiting the information we keep, limiting what we do with your information, who we send your information to, etc.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How we protect your information</h2>
                                    <p>Our teams work tirelessly to protect your information, and to ensure the security and integrity of our platform. We also have independent auditors assess the security of our data storage and systems that process financial information. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How we use “cookies” and other tracking technologies</h2>
                                    <p>We use cookies and similar tracking technologies on our website and when providing our services. For more information about how we use these technologies please see our Cookie Policy.</p>
                                </div>

                                <div className="TabCommonBox">
                                    <h2>How you can reach us</h2>
                                    <p>If you would like to ask about, make a request relating to, or complain about how we process your personal information, you can contact us by email at privacy@bindle.com</p>
                                </div> */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </FooterHeaderWrapper>
    )
}

export default PrivacyPolicy;