import { LOGINAPI, VERIFYLOGIN, REGISTERAPI, VERIFYREGISTRATION, UPDATEUSERDETAILS , UPDATEPROFILEIMAGE} from "./url";
import axios from "axios";

class AuthServiceClass {

    async loginService(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.post(`${LOGINAPI}`,data,{
            headers: headers,
        })
    }

    async verifyLoginService(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.post(`${VERIFYLOGIN}`, data, {
            headers: headers,
        })
    }

    async registerService(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.post(`${REGISTERAPI}`,data,{
            headers: headers,
        })
    }

    async verifyRegisterService(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.post(`${VERIFYREGISTRATION}`,data,{
            headers: headers,
        })
    }

    async updateUserService(data){
        const access_token = localStorage.getItem('token');
        console.log('accesss_token service -- ', access_token);
        const headers = {
            "content-type": "application/json",
            'Authorization': 'Bearer ' + access_token
        }

        return await axios.post(`${UPDATEUSERDETAILS}`,data,{
            headers: headers,
        })
    } 
    async updateProfileImageService(data){
        const access_token = localStorage.getItem('token');
        console.log('accesss_token service -- ', access_token);
        const headers = {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${access_token}`
        }

        return await axios.post(`${UPDATEPROFILEIMAGE}`,data,{
            headers: headers,
        })
    } 


}


const AuthService = new AuthServiceClass()

export default AuthService;