import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useHistory } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import { Rings } from "react-loader-spinner";


const Header = ({ modalStatus }) => {
    const navigate = useNavigate();
    const registerResData = useSelector((state) => state.auth.registeredUserData);
    console.log(registerResData);
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        terms_conditions: false
    });
    const [regOtp, setRegOtp] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: ""
    });
    const [loginUser, setLoginUser] = useState({
        email: ""
    });
    const [loginOtp, setLoginOtp] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: ""
    });

    useEffect(() => {
        if (modalStatus !== undefined || modalStatus !== null) {
            setShowModal(modalStatus)
        }
    }, [modalStatus])

    // Modals start
    const [showModal, setShowModal] = useState(false);
    const [verifyOtpModal, setVerifyOtpModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [verifyLoginOtpModal, setVerifyLoginOtpModal] = useState(false);
    // Modals end

    const [loader, setLoader] = useState(false);
    const [resendLoader, setResendLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let errors = {};
        // let emailValidationRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        let mobileNumberRegex = /^[0-9]{10}$/;
        var pattern = /^[A-Za-z]+$/;

        if (!data.first_name) {
            errors.first_name = 'First name is equired';
        } else if (data.first_name.length < 3) {
            errors.first_name = 'First name should be atleast 3 words';
        } else if (!pattern.test(data.first_name)) {
            errors.first_name = 'First name should contains only alphabets';
        }

        if (!data.last_name) {
            errors.last_name = 'Last name is equired';
        } else if (data.last_name.length < 3) {
            errors.last_name = 'Last name should be atleast 3 words';
        } else if (!pattern.test(data.last_name)) {
            errors.last_name = 'Last name should contains only alphabets';
        }

        if (data.phone_number) {
            if (!mobileNumberRegex.test(data.phone_number)) {
                errors.phone_number = 'Phone number contains only 10 digits';
            }
        }


        // if (!/\S+@\S+\.\S+/.test(data.email)) {
        if (!emailValidationRegex.test(data.email)) {
            errors.email = 'Email is invalid';
        }

        if (!data.terms_conditions) {
            errors.terms_conditions = 'Terms & conditions is required';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            setLoader(true);
            let res = await actions.registerAction(data);

            if (!res?.data?.status || res?.data?.status === false) {
                setShowModal(true);
                toast.error(res?.data?.message);
                setVerifyOtpModal(false);
                setLoader(false);
            } else {
                console.log('Registration successfully done!');
                toast.success("Registration OTP for " + res?.data?.data?.email + " - " + res?.data?.otp);
                setShowModal(false);
                setVerifyOtpModal(true);
                setLoader(false);
            }
        } else {
            setErrors(validationErrors);
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value })
    }

    // handle Terms and Conditions checkbox
    const handleTermsConditions = (e) => {
        const { name, checked } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    //redirection after successful login
    // const history = useHistory();
    // const currentPath = window.location.pathname;
    // const history = useHistory();
    // const returnUrl = location.pathname + location.search;
    // console.log('redirection after login --- header --- ',currentPath);

    const AccountCreatedSuccessfully = () => {
        //setLoginModal(true);
        setSuccessModal(false);
        setData({
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
            terms_conditions: false
        });
        setErrors({});
        // navigate('/')
    }

    //verify registration otp
    const validateOtpForm = () => {
        let errors = {};

        if (!regOtp.otp1) {
            errors.otp1 = 'All fields are required';
        } else if (!regOtp.otp2) {
            errors.otp2 = 'All fields are required';
        } else if (!regOtp.otp3) {
            errors.otp3 = 'All fields are required';
        } else if (!regOtp.otp4) {
            errors.otp4 = 'All fields are required';
        }

        return errors;
    };

    const submitVerifyRegistrationOtp = async (e) => {
        e.preventDefault();
        const validationErrors = validateOtpForm();

        if (Object.keys(validationErrors).length === 0) {
            setLoader(true);

            let regVerifyOtp = regOtp?.otp1 + regOtp?.otp2 + regOtp?.otp3 + regOtp?.otp4;
            let email = data?.email;

            let verifyOtpArr = {
                "email": email,
                "otp": regVerifyOtp
            }

            let res = await actions.registerOtpAction(verifyOtpArr);
            // console.log('otp verification for registration - ', res);

            if (!res?.data?.status || res?.data?.status === false) {
                setVerifyOtpModal(true);
                toast.error(res?.data?.message);
                setLoader(false);
            } else {
                console.log('OTP verified successfully!');
                toast.success("OTP verified successfully!");
                setVerifyOtpModal(false);
                setSuccessModal(true);
                setData({
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                    email: ""
                });
                setRegOtp({
                    otp1: "",
                    otp2: "",
                    otp3: "",
                    otp4: ""
                });
                setLoader(false);
            }
        } else {
            setErrors(validationErrors);
        }
    }

    const handleOtp = (e) => {
        let { name, value } = e.target;
        setRegOtp({ ...regOtp, [name]: value })
    }

    //Login user
    const validateLoginForm = () => {
        let errors = {};
        let emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        if (!loginUser.email) {
            errors.email = 'Email is required';
            // } else if (loginUser.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        } else if (!emailValidationRegex.test(loginUser.email)) {
            errors.email = 'Email is invalid';
        }

        return errors;
    };

    const submitLoginUser = async (e) => {
        e.preventDefault();
        const validationErrors = validateLoginForm();
        console.log("validationErrors ---- ", loginUser);

        if (Object.keys(validationErrors).length === 0) {
            setLoader(true);
            let res = await actions.loginAction(loginUser);
            console.log('login using email - ', res?.data);

            if (!res?.data?.status || res?.data?.status === false) {
                setLoginModal(true);
                toast.error(res?.data?.message);
                setVerifyLoginOtpModal(false);
                setLoader(false);
            } else {
                console.log('User found');
                toast.success("Otp for " + loginUser?.email + ' - ' + res?.data?.otp);
                setLoginModal(false);
                setVerifyLoginOtpModal(true);
                setLoader(false);
            }
        } else {
            setErrors(validationErrors);
        }
    }

    //resend login otp
    const resendLoginOtp = async (e) => {
        e.preventDefault();
        const validationErrors = validateLoginForm();
        console.log("validationErrors ---- ", loginUser);

        if (Object.keys(validationErrors).length === 0) {
            setResendLoader(true);

            let res = await actions.loginAction(loginUser);
            console.log('login using email - ', res?.data);

            if (!res?.data?.status || res?.data?.status === false) {
                setLoginModal(true);
                toast.error(res?.data?.message);
                setVerifyLoginOtpModal(false);
                setResendLoader(false);
            } else {
                console.log('User found');
                toast.success("Otp for " + loginUser?.email + ' - ' + res?.data?.otp);
                setLoginModal(false);
                setVerifyLoginOtpModal(true);
                setResendLoader(false);
            }
        } else {
            setErrors(validationErrors);
        }
    }

    const handleLoginChange = (e) => {
        let { name, value } = e.target;
        setLoginUser({ ...loginUser, [name]: value });
    }

    //verify login otp
    const validateLoginOtpForm = () => {
        let errors = {};

        if (!loginOtp.otp1) {
            errors.otp1 = 'All fields are required';
        } else if (!loginOtp.otp2) {
            errors.otp2 = 'All fields are required';
        } else if (!loginOtp.otp3) {
            errors.otp3 = 'All fields are required';
        } else if (!loginOtp.otp4) {
            errors.otp4 = 'All fields are required';
        }

        return errors;
    };

    const handleLoginOtp = (e) => {
        let { name, value } = e.target;
        setLoginOtp({ ...loginOtp, [name]: value });
    }

    const verifyLoginOtp = async (e) => {
        e.preventDefault();
        const validationErrors = validateLoginOtpForm();

        if (Object.keys(validationErrors).length === 0) {
            setLoader(true);

            let regVerifyOtp = loginOtp?.otp1 + loginOtp?.otp2 + loginOtp?.otp3 + loginOtp?.otp4;
            let email = loginUser?.email;

            let verifyOtpArr = {
                "email": email,
                "otp": regVerifyOtp
            }

            let res = await actions.loginOtpAction(verifyOtpArr);
            // console.log('otp verification for registration - ', res?.data?.data);

            if (!res?.data?.status || res?.data?.status === false) {
                setVerifyLoginOtpModal(true);
                toast.error(res?.data?.message);
                setLoader(false);
            } else {
                console.log('OTP verified successfully!');
                toast.success("Welcome to Shadescape", {
                    position: 'top-right',
                    autoClose: 4000
                });
                setVerifyLoginOtpModal(false);
                setLoginUser({
                    email: ""
                });
                setLoginOtp({
                    otp1: "",
                    otp2: "",
                    otp3: "",
                    otp4: ""
                });

                // navigate("/");
                window.location.reload();
                setLoader(false);
            }
        } else {
            setErrors(validationErrors);
        }
    }

    const location = useLocation();
    const isHome = location.pathname === '/';

    console.log(localStorage.getItem('token'));

    //resort list by type
    const handleResortList = async (e, data) => {

        e.preventDefault();
        let res = await actions.getResortListByTypeAction(data);

        if (res.data.success === true) {
            // toast.success(res.data.resort_list_count + ' Results Found');
            actions.getResortListAction();
            navigate('/resort-list', { state: { searchData: { holidayType: data, checkInDate: null, checkOutDate: null } } })
        } else {
            toast.warning(res.data.message);
        }
        console.log('handle resort list--- ', res.data);
    }


    // Header scrolling background
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('header');
            if (header) {
                header.classList.toggle('header-scrolled', window.scrollY > 50);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // resort list
    const resortList = useSelector((state) => state?.resort?.resortList);
    console.log(" resortList?.resort_type_list", resortList)
    useEffect(() => {
        actions.getResortListAction();
    }, []);

    //holiday type list for deopdown
    const holidayTypeList = useSelector((state) => state?.resort?.resortHolidayList?.data);
    useEffect(() => {
        actions.getResortHolidayAction();
    }, []);
    // console.log('header resort list ---- ',resortList);



    return (
        <>
            <header className={isHome ? '' : 'Header'}>
                {/* <header className={color ? 'header-scrolled' : ''}> */}
                <nav className="navbar navbar-expand-lg">
                    <Link to="/" className="navbar-brand">
                        <img src={require("../../assets/images/logo.png")} alt="logo-img" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="fa-solid fa-bars"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about-us" className="nav-link">About us</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/packages" className="nav-link">Packages</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown">
                                    Resorts
                                </a>
                                <ul className="dropdown-menu">
                                    {
                                        holidayTypeList && holidayTypeList?.map((item, index) => (
                                            <li key={index}>
                                                <Link to="#" className="dropdown-item" value={item?.holiday_type} onClick={(e) => handleResortList(e, item?.holiday_type)}>{item?.holiday_type}</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>

                        </ul>
                    </div>

                    <div className="NavbarLogin">
                        <ul>
                            <li><Link to='#' data-toggle="modal" data-target="#LoginModal" onClick={() => setShowModal(true)}>Sign up</Link></li>
                            <li><Link to='#' className="LoginBtn" data-toggle="modal" data-target="#SignInModalDesktop" onClick={() => setLoginModal(true)}>Login</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <div className="ModalBox">
                <Modal show={showModal} className="ModalBox">
                    <Modal.Body>
                        <div className="Category">

                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setShowModal(false)}>×</Link>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog" src={require("../../assets/images/blog-heading.png")} /></Link>
                                                <h2>Create Account</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" value={data?.first_name} name="first_name" onChange={handleChange} placeholder="Enter First Name" />
                                                {errors.first_name && <span style={{ color: 'red' }}>{errors.first_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" value={data?.last_name} name="last_name" onChange={handleChange} placeholder="Enter Last Name" />
                                                {errors.last_name && <span style={{ color: 'red' }}>{errors.last_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number (Optional)</label>
                                                <div className="PhoneSearchArea">
                                                    <input type="text" className="form-control SmallInput" placeholder="+91" />
                                                    <input type="number" className="form-control" placeholder="Enter Phone Number" value={data?.phone_number} name="phone_number" onChange={handleChange} />
                                                </div>
                                                {errors.phone_number && <span style={{ color: 'red' }}>{errors.phone_number}</span>}
                                            </div>
                                            <div className="form-group">
                                                <label>Email Address </label>
                                                <input type="text" className="form-control" placeholder="Enter Email Address" value={data?.email} name="email" onChange={handleChange} />
                                                {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox"
                                                    name="terms_conditions"
                                                    className="form-check-input"
                                                    id="terms_conditions"
                                                    checked={data?.terms_conditions}
                                                    onChange={handleTermsConditions} />
                                                <label>I agree with the <Link to="/terms-conditions" style={{ cursor: 'pointer' }}>Terms & Conditions </Link>
                                                    of shadescapes</label>
                                                <br />{errors.terms_conditions && <span style={{ color: 'red' }}>{errors.terms_conditions}</span>}
                                            </div>
                                            <button type="submit" disabled={loader} className="FormButton FolderPermissionId" data-toggle="modal" data-target="#OtpVerificationModal" data-dismiss="modal">
                                                {/* Sign Up */}
                                                {loader ? <div className="loader-container">
                                                    <Rings
                                                        visible={true}
                                                        height="50"
                                                        width="50"
                                                        color="#4fa94d"
                                                        ariaLabel="rings-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                </div> : 'Sign Up'}
                                            </button>
                                            <p className="AlreadyAccount">Already have an account? <Link to="#" className="FolderPermissionId" data-toggle="modal" data-target="#SignInModal2" data-dismiss="modal" onClick={() => { setShowModal(false); setLoginModal(true) }}>Sign In</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal show={verifyOtpModal} className="ModalBox">
                    <Modal.Body>
                        <div className="FormArea">
                            <Link href="#" className="CloseModal" data-dismiss="modal" onClick={() => setVerifyOtpModal(false)}>×</Link>
                            <form onSubmit={submitVerifyRegistrationOtp}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="modal-title">
                                            <Link to="#"><img alt="blog-heading" src={require("../../assets/images/blog-heading.png")} /></Link>
                                            <h2>OTP Verification</h2>
                                            <p>Enter the 4-digit code sent to you at </p>
                                            {/* <p className="EditNumber">{data?.phone_number} <Link to="#">Edit</Link></p> */}
                                            <p className="EditNumber">{data?.email} </p>
                                        </div>
                                        <div className="form-group">
                                            <div className="OTPBox">
                                                <div className="row">
                                                    <input type="text"
                                                        name="otp1"
                                                        id="otp1"
                                                        value={regOtp?.otp1}
                                                        className="form-control"
                                                        maxLength="1"
                                                        onChange={handleOtp}
                                                        // style={{ marginLeft: '20px' }}
                                                        onInput={e => {
                                                            if (e.target.value.length === e.target.maxLength) {
                                                                // If the current input field is filled, move to the next one
                                                                e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                            }
                                                        }}
                                                    />

                                                    <input type="text"
                                                        name="otp2"
                                                        id="otp2"
                                                        value={regOtp?.otp2}
                                                        className="form-control"
                                                        maxLength="1"
                                                        onChange={handleOtp}
                                                        // style={{ marginLeft: '20px' }}
                                                        onInput={e => {
                                                            if (e.target.value.length === e.target.maxLength) {
                                                                e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                            } else if (e.target.value.length === 0) {
                                                                e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                            }
                                                        }}
                                                    />

                                                    <input type="text"
                                                        name="otp3"
                                                        id="otp3"
                                                        value={regOtp?.otp3}
                                                        className="form-control"
                                                        maxLength="1"
                                                        onChange={handleOtp}
                                                        // style={{ marginLeft: '20px' }}
                                                        onInput={e => {
                                                            if (e.target.value.length === e.target.maxLength) {
                                                                e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                            } else if (e.target.value.length === 0) {
                                                                e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                            }
                                                        }}
                                                    />

                                                    <input type="text"
                                                        name="otp4"
                                                        id="otp4"
                                                        value={regOtp?.otp4}
                                                        className="form-control"
                                                        maxLength="1"
                                                        onChange={handleOtp}
                                                        // style={{ marginLeft: '20px' }}
                                                        onInput={e => {
                                                            if (e.target.value.length === 0) {
                                                                e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                            }
                                                        }}
                                                    />


                                                    <div className="col-md-12">
                                                        {(errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4) && <span style={{ color: 'red' }}>{errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <span className="OtpTimer">00:00s</span>
                                    <Link to="#" className="ResendBtn">Resend</Link>
                                    <button type="submit" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#CongratulationsModal" data-dismiss="modal">
                                        {/* Verify */}
                                        {loader ? <div className="loader-container">
                                            <Rings
                                                visible={true}
                                                height="50"
                                                width="50"
                                                color="#4fa94d"
                                                ariaLabel="rings-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </div> : 'Verify'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={successModal} className="ModalBox">
                    <Modal.Body>
                        <div className="FormArea Congratulatios">
                            <Link to="" className="CloseModal" data-dismiss="modal" onClick={() => setSuccessModal(false)}>×</Link>
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="modal-title">
                                            <Link to=""><img alt="congratulation" src={require("../../assets/images/congratulation.png")} /></Link>
                                            <h2>Congratulations</h2>
                                            <p>Your Account has been created successfully</p>
                                        </div>
                                    </div>
                                    <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#SignInModal" data-dismiss="modal" onClick={AccountCreatedSuccessfully}>Back to Home</Link>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={loginModal} className="ModalBox">
                    <Modal.Body>
                        <div className="FormArea">
                            <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setLoginModal(false)}>×</Link>

                            <form onSubmit={submitLoginUser}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="modal-title">
                                            <Link to="#"><img alt="blog-heading-img" src={require("../../assets/images/blog-heading.png")} /></Link>
                                            <h2>Sign In</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            {/* <div className="PhoneSearchArea"> */}
                                            <div className="">
                                                <input type="text" className="form-control" name="email" value={loginUser?.last_name} placeholder="Enter your email" onChange={handleLoginChange} />
                                                {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" />
                                            <label className="form-check-label">Remember me </label>
                                        </div>
                                        <button type="submit" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#SignInOtpModal" data-dismiss="modal" >
                                            {/* Sign In */}
                                            {loader ? <div className="loader-container">
                                                <Rings
                                                    visible={true}
                                                    height="50"
                                                    width="50"
                                                    color="#4fa94d"
                                                    ariaLabel="rings-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div> : 'Sign In'}
                                        </button>
                                        <p className="AlreadyAccount">Don't have an account? <Link to="#" className="FolderPermissionId" data-toggle="modal" data-target="#LoginModal" data-dismiss="modal" onClick={() => { setShowModal(true); setLoginModal(false); }}>Create Account</Link></p>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={verifyLoginOtpModal} className="ModalBox">
                    <Modal.Body>
                        <div className="FormArea">
                            <Link to="#" className="CloseModal" data-dismiss="modal" onClick={() => setVerifyLoginOtpModal(false)}>×</Link>

                            <form onSubmit={verifyLoginOtp}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="modal-title">
                                            <Link to="#"><img alt="blog-headings-new" src={require("../../assets/images/blog-heading.png")} /></Link>
                                            <h2>OTP Verification</h2>
                                            <p>Enter the 4-digit code sent to you at </p>
                                            {/* <p className="EditNumber">+91 {loginUser?.phone_number} <Link to="#">Edit</Link></p> */}
                                            <p className="EditNumber"> {loginUser?.email} </p>
                                        </div>
                                        <div className="form-group">
                                            <div className="OTPBox">
                                                <input
                                                    type="text"
                                                    name="otp1"
                                                    value={loginOtp?.otp1}
                                                    className="form-control"
                                                    maxLength="1"
                                                    onChange={handleLoginOtp}
                                                    style={{ marginLeft: '20px' }}
                                                    autoFocus
                                                    onInput={e => {
                                                        if (e.target.value.length === e.target.maxLength) {
                                                            // If the current input field is filled, move to the next one
                                                            e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                        }
                                                    }}
                                                />

                                                <input
                                                    type="text"
                                                    name="otp2"
                                                    value={loginOtp?.otp2}
                                                    className="form-control"
                                                    maxLength="1"
                                                    onChange={handleLoginOtp}
                                                    style={{ marginLeft: '20px' }}
                                                    onInput={e => {
                                                        if (e.target.value.length === e.target.maxLength) {
                                                            e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                        } else if (e.target.value.length === 0) {
                                                            e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                        }
                                                    }}
                                                />

                                                <input
                                                    type="text"
                                                    name="otp3"
                                                    value={loginOtp?.otp3}
                                                    className="form-control"
                                                    maxLength="1"
                                                    onChange={handleLoginOtp}
                                                    style={{ marginLeft: '20px' }}
                                                    onInput={e => {
                                                        if (e.target.value.length === e.target.maxLength) {
                                                            e.target.nextElementSibling && e.target.nextElementSibling.focus();
                                                        } else if (e.target.value.length === 0) {
                                                            e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                        }
                                                    }}
                                                />

                                                <input
                                                    type="text"
                                                    name="otp4"
                                                    value={loginOtp?.otp4}
                                                    className="form-control"
                                                    maxLength="1"
                                                    onChange={handleLoginOtp}
                                                    style={{ marginLeft: '20px' }}
                                                    onInput={e => {
                                                        if (e.target.value.length === 0) {
                                                            e.target.previousElementSibling && e.target.previousElementSibling.focus(); // Move back if deleting
                                                        }
                                                    }}
                                                />

                                                <div className="col-md-12">
                                                    {(errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4) && <span style={{ color: 'red' }}>{errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4}</span>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <span className="OtpTimer">00:00s</span>
                                    <Link to="#" onClick={resendLoginOtp} className="ResendBtn">
                                        {/* Resend */}
                                        {resendLoader ? <div className="loader-container">
                                            <Rings
                                                visible={true}
                                                height="50"
                                                width="50"
                                                color="#4fa94d"
                                                ariaLabel="rings-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </div> : 'Resend'}
                                    </Link>
                                    <button type="submit" className="FormButton">
                                        {/* Verify */}
                                        {loader ? <div className="loader-container">
                                            <Rings
                                                visible={true}
                                                height="50"
                                                width="50"
                                                color="#4fa94d"
                                                ariaLabel="rings-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </div> : 'Verify'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* <div className="modal fade SmallModal" id="SignInModal2" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-dismiss="modal">×</Link>

                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog-heaing2" src="assets/images/blog-heading.png" /></Link>
                                                <h2>Sign In</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <div className="PhoneSearchArea">
                                                    <input type="text" className="form-control SmallInput" placeholder="+91" />
                                                    <input type="text" className="form-control" placeholder="Enter Phone Number" />
                                                </div>
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" />
                                                <label className="form-check-label">Remember me </label>
                                            </div>
                                            <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#SignInOtpModal2" data-dismiss="modal">Sign In</Link>
                                            <p className="AlreadyAccount">Don't have an account? <Link to="#" className="FolderPermissionId" data-toggle="modal" data-target="#LoginModal2" data-dismiss="modal">Create Account</Link></p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade SmallModal" id="SignInOtpModal2" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-dismiss="modal">×</Link>

                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog-heading3" src="assets/images/blog-heading.png" /></Link>
                                                <h2>OTP Verification</h2>
                                                <p>Enter the 4-digit code sent to you at </p>
                                                <p className="EditNumber">+91 99999999999 <Link to="#">Edit</Link></p>
                                            </div>
                                            <div className="form-group">
                                                <div className="OTPBox">
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                </div>
                                            </div>

                                        </div>
                                        <span className="OtpTimer">00:00s</span>
                                        <Link to="#" className="ResendBtn">Resend</Link>
                                        <Link to="/login" className="FormButton">Verify</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="modal fade SmallModal" id="LoginModal2" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-toggle="modal" data-target="#SignInModal2" data-dismiss="modal">×</Link>
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog-heading4" src="assets/images/blog-heading.png" /></Link>
                                                <h2>Create Account</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" placeholder="Enter First Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Last Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <div className="PhoneSearchArea">
                                                    <input type="text" className="form-control SmallInput" placeholder="+91" />
                                                    <input type="text" className="form-control" placeholder="Enter Phone Number" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Email Address (Optional)</label>
                                                <input type="text" className="form-control" placeholder="Enter First Name" />
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" />
                                                <label className="form-check-label">I agree with the <span>Terms & Conditions</span>
                                                    of shadescapes</label>
                                            </div>
                                            <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#OtpVerificationModal" data-dismiss="modal">Sign Up</Link>
                                            <p className="AlreadyAccount">Already have an account? <Link to="#" className="FolderPermissionId" data-toggle="modal" data-target="#SignInModal2" data-dismiss="modal">Sign In</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade SmallModal" id="OtpVerificationModal2" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-toggle="modal" data-target="#LoginModal" data-dismiss="modal">×</Link>
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="imageblog" src="assets/images/blog-heading.png" /></Link>
                                                <h2>OTP Verification</h2>
                                                <p>Enter the 4-digit code sent to you at </p>
                                                <p className="EditNumber">+91 99999999999 <Link to="#">Edit</Link></p>
                                            </div>
                                            <div className="form-group">
                                                <div className="OTPBox">
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                </div>
                                            </div>

                                        </div>
                                        <span className="OtpTimer">00:00s</span>
                                        <Link to="#" className="ResendBtn">Resend</Link>
                                        <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#CongratulationsModal" data-dismiss="modal">Verify</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade SmallModal" id="CongratulationsModal2" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea Congratulatios">
                                <Link to="#" className="CloseModal" data-toggle="modal" data-target="#OtpVerificationModal" data-dismiss="modal">×</Link>

                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="congratulation-images" src="assets/images/congratulation.png" /></Link>
                                                <h2>Congratulations</h2>
                                                <p>Your Account has been created successfully</p>
                                            </div>
                                        </div>
                                        <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#SignInModal" data-dismiss="modal">Back to Sign in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade SmallModal" id="SignInModalDesktop" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-dismiss="modal">×</Link>

                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog-img" src="assets/images/blog-heading.png" /></Link>
                                                <h2>Sign In</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <div className="PhoneSearchArea">
                                                    <input type="text" className="form-control SmallInput" placeholder="+91" />
                                                    <input type="text" className="form-control" placeholder="Enter Phone Number" />
                                                </div>
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" />
                                                <label className="form-check-label">Remember me </label>
                                            </div>
                                            <Link to="#" className="FormButton FolderPermissionId" data-toggle="modal" data-target="#SignInOtpModal" data-dismiss="modal">Sign In</Link>
                                            <p className="AlreadyAccount">Don't have an account? <Link to="#" className="FolderPermissionId" data-toggle="modal" data-target="#LoginModal" data-dismiss="modal">Create Account</Link></p>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade SmallModal" id="SignInOtpModalDesktop" tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="FormArea">
                                <Link to="#" className="CloseModal" data-dismiss="modal">×</Link>

                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="modal-title">
                                                <Link to="#"><img alt="blog-head" src="assets/images/blog-heading.png" /></Link>
                                                <h2>OTP Verification</h2>
                                                <p>Enter the 4-digit code sent to you at </p>
                                                <p className="EditNumber">+91 99999999999 <Link to="#">Edit</Link></p>
                                            </div>
                                            <div className="form-group">
                                                <div className="OTPBox">
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                    <input type="text" className="form-control" maxLength="1" />
                                                </div>
                                            </div>

                                        </div>
                                        <span className="OtpTimer">00:00s</span>
                                        <Link to="#" className="ResendBtn">Resend</Link>
                                        <Link to="/login" className="FormButton">Verify</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}


            </div>
            <ToastContainer />

        </>
    );
}

export default Header;