import { BANNERLIST } from "./url";
import axios from "axios";

class BannerServiceClass {
    async getBannerContent(data){
        const headers = {
            "content-type": "application/json"
        }

        return await axios.get(`${BANNERLIST}`,data,{
            headers: headers,
        })
    }
}

const BannerService = new BannerServiceClass();

export default BannerService;